import React from "react"

const SvgComponent = props => (
	<svg x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
		<path
			d="M104 0C86.328 0 72 14.328 72 32v448c0 17.672 14.328 32 32 32h304c17.672 0 32-14.328 32-32V128L312 0H104z"
			fill="#e2e7f0"
		/>
		<g fill="#9dacba">
			<path d="M344 128h96L312 0v96c0 17.672 14.328 32 32 32zM192 160h128c4.416 0 8 3.584 8 8s-3.584 8-8 8H192c-4.416 0-8-3.584-8-8s3.584-8 8-8zM144 328h224c4.416 0 8 3.584 8 8s-3.584 8-8 8H144c-4.416 0-8-3.584-8-8s3.584-8 8-8zM144 424h128c4.416 0 8 3.584 8 8s-3.584 8-8 8H144c-4.416 0-8-3.584-8-8s3.584-8 8-8zM144 376h224c4.416 0 8 3.584 8 8s-3.584 8-8 8H144c-4.416 0-8-3.584-8-8s3.584-8 8-8zM144 280h224c4.416 0 8 3.584 8 8s-3.584 8-8 8H144c-4.416 0-8-3.584-8-8s3.584-8 8-8zM144 232h224c4.416 0 8 3.584 8 8s-3.584 8-8 8H144c-4.416 0-8-3.584-8-8s3.584-8 8-8z" />
		</g>
		<path
			d="M344 128l75.28 75.28a16.019 16.019 0 014.72 11.36V480c0 8.84-7.16 16-16 16H120c-8.84 0-16 7.16-16 16h304c17.672 0 32-14.328 32-32V128h-96z"
			fill="#c9d5e3"
		/>
	</svg>
)

export default SvgComponent;
