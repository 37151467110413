import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({color, hoverColor, activeColor, isHover, isActive, ...props}) => (
	<svg width={29.523} height={26.238} viewBox="0 0 29.523 26.238" {...props}>
		<path
			d="M20.636 4.359l4.623 4.623a.5.5 0 010 .707L14.065 20.884l-4.757.528a1 1 0 01-1.1-1.1l.528-4.757L19.928 4.359a.5.5 0 01.708 0zm8.3-1.174l-2.5-2.5a2 2 0 00-2.829 0L21.794 2.5a.5.5 0 000 .707l4.623 4.623a.5.5 0 00.707 0l1.814-1.814a2 2 0 00.001-2.83zM19.682 17.84v5.218H3.28V6.656h11.779a.63.63 0 00.436-.179l2.05-2.05a.615.615 0 00-.436-1.051H2.46A2.461 2.461 0 000 5.836v18.041a2.461 2.461 0 002.46 2.46H20.5a2.461 2.461 0 002.46-2.46v-8.088a.616.616 0 00-1.051-.436l-2.05 2.05a.63.63 0 00-.177.437z"
			transform="translate(0 -.1)"
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
	</svg>
)

SvgComponent.propTypes = {
	color: PropTypes.string,
	hoverColor: PropTypes.string,
	activeColor: PropTypes.string,
	isHover: PropTypes.bool,
	isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
	color: '#6c757e',
	hoverColor: '#6c757e',
	activeColor: '#6c757e',
	isHover: false,
	isActive: false,
}

export default SvgComponent
