import React from 'react'

const SvgComponent = props => (
	<svg viewBox='0 0 57 49.875' {...props}>
		<path
			d='M2.036 69.161h52.928A2.036 2.036 0 0057 67.125v-5.089A2.036 2.036 0 0054.964 60H2.036A2.036 2.036 0 000 62.036v5.089a2.036 2.036 0 002.036 2.036zm0 20.357h52.928A2.036 2.036 0 0057 87.482v-5.089a2.036 2.036 0 00-2.036-2.036H2.036A2.036 2.036 0 000 82.393v5.089a2.036 2.036 0 002.036 2.036zm0 20.357h52.928A2.036 2.036 0 0057 107.839v-5.089a2.036 2.036 0 00-2.036-2.036H2.036A2.036 2.036 0 000 102.75v5.089a2.036 2.036 0 002.036 2.036z'
			transform='translate(0 -60)'
			fill='#081e3f'
		/>
	</svg>
)

export default SvgComponent
