import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ title, description }) => {
	const { pathname } = useLocation()
	const { site } = useStaticQuery(query)

	const { defaultTitle, titleTemplate, defaultDescription, siteUrl } =
		site.siteMetadata

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		url: `${siteUrl}${pathname}`,
	}

	return (
		<Helmet title={seo.title} titleTemplate={titleTemplate} defer={false}>
			<html lang='id' />
			<meta name='description' content={seo.description} />

			{seo.url && <link rel='canonical' href={seo.url}></link>}

			{seo.url && <meta property='og:url' content={seo.url} />}

			{seo.title && <meta property='og:title' content={seo.title} />}

			{seo.description && (
				<meta property='og:description' content={seo.description} />
			)}
		</Helmet>
	)
}

export default SEO

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
}

SEO.defaultProps = {
	title: null,
	description: null,
}

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle: title
				titleTemplate
				defaultDescription: description
				siteUrl: url
			}
		}
	}
`
