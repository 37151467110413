import React from 'react'

const SvgComponent = props => (
	<svg
		id='Layer_1'
		x='0px'
		y='0px'
		viewBox='0 0 71.5 74.1'
		xmlSpace='preserve'
		{...props}
		fill='none'
		strokeWidth='2'
	>
		<path d='M35.6 42.9c1.4.3 1.7 2.1.4 2.8L33.6 47c-.3.2-.8 1.7-.5 2.2l1.5 2.2c.8 1.2-.3 2.7-1.7 2.3l-2.6-.8c-.4-.1-1.8.7-1.9 1.2l-.5 2.7c-.3 1.4-2.1 1.7-2.8.4l-1.3-2.4c-.2-.4-1.8-.8-2.2-.5l-2.2 1.5c-1.2.8-2.7-.3-2.3-1.7l.8-2.6c.1-.5-.8-1.8-1.2-1.9l-2.7-.5c-1.4-.3-1.7-2.1-.4-2.8L16 45c.4-.2.8-1.8.5-2.2L15 40.6c-.8-1.2.3-2.7 1.7-2.3l2.6.8c.4.1 1.8-.7 1.9-1.2l.5-2.7c.3-1.4 2.1-1.7 2.8-.4l1.3 2.4c.2.4 1.8.8 2.2.5l2.2-1.5c1.2-.8 2.7.3 2.3 1.7l-.8 2.6' />
		<circle cx={24.8} cy={46} r={5.1} />
		<path d='M34.9 32.9l-3.1-.6c-1.6-.3-2-2.5-.5-3.2l2.8-1.5c.4-.2.9-2.1.6-2.5l-1.8-2.6c-.9-1.4.4-3.1 1.9-2.7l3 .9c.5.1 2.1-.8 2.2-1.3l.6-3.1c.3-1.6 2.5-2 3.2-.5l1.5 2.8c.2.5 2.1.9 2.5.6l2.6-1.8c1.4-.9 3.1.4 2.7 1.9l-.9 3c-.1.5.8 2.1 1.3 2.2l3.1.6c1.6.3 2 2.5.5 3.2l-2.8 1.5c-.4.2-.9 2-.6 2.5l1.8 2.6c.9 1.4-.4 3.1-1.9 2.7l-3-.9c-.5-.1-2.1.8-2.2 1.3l-.6 3.1c-.3 1.6-2.5 2-3.2.5l-1.5-2.8c-.2-.4-2.1-.9-2.5-.6L38 40.1c-1.4.9-3.1-.4-2.7-1.9' />
		<circle cx={44.2} cy={28.7} r={6} />
		<path d='M-73 16.8c5.1-1.4 10.1-.4 13.3 3.1 5.1 5.5 3.5 15.2-3.5 21.6s-16.7 7.2-21.8 1.7c-3.7-4-3.9-10.3-1-15.9M-95.7 56.6c-.6.5-1.5.5-2-.1l-1.6-1.7c-.5-.6-.5-1.5.1-2l7.4-6.8c.6-.5 1.5-.5 2 .1l1.6 1.7c.5.6.5 1.5-.1 2l-7.4 6.8z' />
		<path d='M-62.3 22.3L-82.4 40.8' />
		<path d='M-60.1 27.2L-78.3 44' />
		<path d='M-78.5 30.4L-85.2 36.5' />
		<path d='M-67 19.7L-72.3 24.6' />
		<path d='M-70.3 22.8L-61.3 32.6' />
		<path d='M-73.5 26.8L-63.2 37.9' />
		<path d='M-76.1 31.1L-67.4 40.6' />
		<path d='M-83.2 30.7L-71.6 43.3' />
		<path d='M-88.4 47c1.9-1.8 4.6-2.2 6.9-1.3M-87.1 39.5c.7 2.4.1 5.1-1.9 6.9' />
		<circle cx={-80.2} cy={21.2} r={6} />
		<path d='M-83 16.6c.6 1.4 1.7 2.5 3.3 3 1.6.5 3.2.2 4.4-.6M-77.4 25.8c-.6-1.4-1.7-2.5-3.3-3-.8-.3-1.6-.3-2.4-.2' />
		<g>
			<path d='M-202.8 34.9h28.5c2.6 0 4.8 2.1 4.8 4.8v5c0 2.6-2.1 4.8-4.8 4.8h-24.3' />
			<circle cx={-178.9} cy={42.2} r={2.6} />
			<path d='M-182.8 49.8v3.9c0 1.2 1 2.2 2.2 2.2h2.6c1.2 0 2.2-1 2.2-2.2v-3.9M-199.9 19.9h18c3.4 0 6.1 2.8 6.1 6.2V31' />
			<path d='M-201.3 40.2L-187.2 40.2' />
			<path d='M-199.4 44.1L-189.2 44.1' />
			<path d='M-206.2 28.1c2-1.1 3.4-3.1 3.4-5.5 0-2.7-1.7-4.9-4.1-5.8v6s-1 .5-2.3.5c-1.3 0-2.3-.5-2.3-.5v-6c-2.4.9-4.1 3.2-4.1 5.8 0 2.4 1.4 4.5 3.5 5.6v16.4c-2.1 1-3.5 3.1-3.5 5.6 0 2.7 1.7 4.9 4.1 5.8v-6s1-.5 2.3-.5c1.3 0 2.3.5 2.3.5v6c2.4-.9 4.1-3.2 4.1-5.8 0-2.4-1.4-4.5-3.4-5.5V28.1z' />
		</g>
		<g>
			<path d='M-322.5 32L-322.5 29.2 -312.4 29.2 -312.4 32' />
			<path d='M-317.9 37.4L-325.2 37.4 -322.5 32 -315.6 32' />
			<path d='M-312.4 32L-315.1 37.4' />
			<path d='M-315.1 37.4L-312.4 32 -309.7 37.4z' />
			<path d='M-315.1 37.4L-315.1 56.7 -325.2 56.7 -325.2 37.4' />
			<path d='M-315.1 37.4H-309.6V56.7H-315.1z' />
			<path d='M-322.9 40.1H-317.79999999999995V42.6H-322.9z' />
			<g>
				<path d='M-322.5 26.3L-322.5 19.4 -293.5 19.4' />
				<path d='M-293.5 19.4L-322.5 19.4 -317 16 -287.9 16z' />
				<path d='M-293.5 56.7L-293.5 19.4 -287.9 16 -287.9 53.3 -293.5 56.7' />
				<path d='M-322.5 23.8L-296.2 23.8' />
			</g>
			<g>
				<path d='M-296.2 49.5v-6.7c0-2.8-3.3-8.3-3.3-8.3-.2-.4-.1-.9.3-1.2l.4-.3c.4-.3.7-.8.7-1.3v-.1c0-.5-.4-.9-.9-.9h-5.3c-.5 0-.9.4-.9.9v.3c0 .5.3 1 .8 1.2l.3.2c.4.2.6.7.3 1.1 0 0-3.3 5.4-3.3 8.3v6.7' />
				<path d='M-307 42.8L-299 42.8' />
				<path d='M-306.3 49.5L-299 49.5' />
			</g>
			<path d='M-296.2 49.5L-296.2 56.7 -307 56.7 -307 49.5' />
		</g>
		<g>
			<path d='M-438.7 38.9h-4.9c-2.7 0-4.6-2.5-4.2-5.6l1.9-15.2c.4-3.1 3.3-5.6 6.4-5.6h8.8c3.1 0 6 2.5 6.4 5.6l1.9 15.2c.4 3.1-1.9 5.6-5 5.6h-7.7M-423.4 44.7h-23.4c-1.8 0-3.2 1.4-3.2 3.2 0 1.8 1.4 3.2 3.2 3.2h23.4c1.8 0 3.2-1.4 3.2-3.2 0-1.8-1.5-3.2-3.2-3.2z' />
			<path d='M-435.1 39L-435.1 44.7' />
			<path d='M-416.7 38.2c-2 0-3.5 1.6-3.5 3.5v6.2M-453.6 38.2c2 0 3.5 1.6 3.5 3.5v6.2' />
			<path d='M-435.1 53.6L-435.1 60.2' />
			<path d='M-428.9 60.2c0-1.1-.9-2.1-2.1-2.1h-8.2c-1.1 0-2.1.9-2.1 2.1' />
			<g>
				<path d='M-411.9 30H-389.7V57.7H-411.9z' />
				<path d='M-408.8 38.4L-408.8 32.9 -392.7 32.9 -392.7 38.4 -405.9 38.4' />
				<path d='M-410.5 60.1L-408.2 60.1' />
				<path d='M-393.4 60.1L-391.1 60.1' />
				<path d='M-408.8 46.7L-408.8 41.2 -392.7 41.2 -392.7 46.7 -405.9 46.7' />
				<path d='M-408.8 54.9L-408.8 49.4 -392.7 49.4 -392.7 54.9 -405.9 54.9' />
			</g>
		</g>
		<g>
			<path d='M-533.3 44.4c-1.3.9-2.1 2.4-2.1 4v4.9h-19.2v-4.9c0-1.7-.9-3.2-2.3-4.1' />
			<path d='M-557 62h4.4v-1.9h15V62h7v-8.7c2.7 0 4.9-2.2 4.9-4.9s-2.2-4.9-4.9-4.9v-.3c0-8-6.5-14.5-14.5-14.5s-14.5 6.5-14.5 14.5v.3c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9v8.6' />
			<g>
				<ellipse cx={-521.1} cy={12.2} rx={5.9} ry={1.5} />
				<path d='M-527 12l-4.4 10.6V22.9c0 1.2 3.5 2.3 8.1 2.5M-520.3 61.6V25.5c5.3-.1 9.5-1.2 9.5-2.6v-.2-.1l-4.5-10.7' />
				<path d='M-526.3 62L-514.4 62' />
			</g>
		</g>
		<g>
			<path d='M-673.2 15.7h-4.2v9.8c0 5.4 4.4 9.8 9.8 9.8s9.8-4.4 9.8-9.8v-9.8h-3.9M-667.5 35.3v14.3c0 3.6 3 6.6 6.6 6.6 3.6 0 6.6-3 6.6-6.6v-6.4c0-4.1 3.3-7.4 7.4-7.4s7.4 3.3 7.4 7.4v6.4c2 0 3.7 1.7 3.7 3.7s-1.7 3.7-3.7 3.7-3.7-1.7-3.7-3.7c0-.7.2-1.4.6-2' />
		</g>
		<g>
			<path d='M-750.2 47.4v5.2c0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6v-4.9c0-1.4-1.2-2.6-2.6-2.6-1.4 0-2.6 1.2-2.6 2.6v5.8c0 3.2-2.6 5.8-5.8 5.8-3.2 0-5.8-2.6-5.8-5.8V39.9h6.1c1.6 0 3-1.3 3-3v-9.3h1.6c.9 0 1.6-.7 1.6-1.6v-1.3c0-.9-.7-1.6-1.6-1.6h-5.6v-8.4c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v8.4h-8.6v-8.4c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v8.4h-5.6c-.9 0-1.6.7-1.6 1.6V26c0 .9.7 1.6 1.6 1.6h1.6v9.3c0 1.6 1.3 3 3 3h5.6' />
			<path d='M-746.3 27.9h-3l3.2-5.3c.4-.6.1-1.2-.5-1.2h-4c-.6 0-1 0-1.3.7l-3.3 8.5c-.2.5 0 1 .5 1.2h3.8l-2.8 7.1c-.2.5 7.7-9.4 7.9-9.8.2-.4 0-1-.5-1.2z' />
		</g>
		<path d='M-889.9 17.6l-15.9 3.1c-.6.1-.9.7-.8 1.2l2 8.1c.1.4.5.7 1 .7h6.8c.5 0 1 .4 1 1v24.4c0 .5.4 1 1 1h29.7c.5 0 1-.4 1-1V31.7c0-.5.4-1 1-1h6.9c.4 0 .8-.3.9-.7l2.3-8.1c.2-.6-.2-1.1-.8-1.2l-16.2-3.1c-.6-.1-1.1.3-1.2.8-.5 4.4-4.3 7.8-8.8 7.8-3.6 0-6.6-2.1-8-5.1' />
		<g>
			<path d='M-993.7 23.7c1.2 3.1 2.9 4.4 2.9 4.4s1.6-1.3 2.9-4.4c1.6-3.9-2.9-10-2.9-10s-4.4 6.1-2.9 10zM-989.3 59s2.8-.3 6.4-2.8c3.2-2.2 3.8-4.4 4.1-8.8 0 0-3.5 1.2-7 3.7-1.9 1.4-2.7 2.7-3.1 4.9M-992.3 59s-2.8-.3-6.4-2.8c-3.2-2.2-3.8-4.4-4.1-8.8 0 0 3.5 1.2 7 3.7 1.9 1.4 2.7 2.7 3.1 4.9M-989.3 47.5s2.8-.3 6.4-2.8c3.2-2.2 3.8-4.4 4.1-8.8 0 0-3.5 1.2-7 3.7-1.9 1.4-2.7 2.7-3.1 4.9M-992.3 47.5s-2.8-.3-6.4-2.8c-3.2-2.2-3.8-4.4-4.1-8.8 0 0 3.5 1.2 7 3.7 1.9 1.4 2.7 2.7 3.1 4.9M-989.3 35.9s2.8-.3 6.4-2.8c3.2-2.2 3.8-4.4 4.1-8.8 0 0-3.5 1.2-7 3.7-1.9 1.4-2.7 2.7-3.1 4.9M-992.3 35.9s-2.8-.3-6.4-2.8c-3.2-2.2-3.8-4.4-4.1-8.8 0 0 3.5 1.2 7 3.7 1.9 1.4 2.7 2.7 3.1 4.9' />
		</g>
		<g>
			<path d='M145.2 21.2c-4.1-.7-7 3.7-8.9 3.7-2.4 0-8.1-3.1-10.9-2.4-2.8.6-4.1 15.5-3.1 17.9 1 2.4 11.2 1.8 10.6 4.7-.6 2.7 1.2 4.4 4.6 4 .8 1.8 2.3 2.7 4.8 2.7.2 1.8 3.7 3.3 5 2.8-.2 2.3 3.6 3.9 5.2 2.7.7-.5 1-1 1.4-1.7' />
			<path d='M174 42.8c1.6-.2 5.3-1 5.9-2.5 2.4-6.4-1.4-16.9-3.2-17.8-1.8-.8-6.7 1.9-10.1 2.1-3.8.3-8.8-6-12.1-6-2.5 0-7.1 3.6-8 5.4-.9 1.9-1.9 6.2-1.9 8.9 0 2.8 6.1 3.5 6.7 1.5.9-2.9 1.1-5.5 1.7-6.3s2-2.1 3.7-1c7.5 4.8 15.1 8.3 15.7 9.5.8 1.8-1.1 4.7-3 4.3 1.5 2-.8 6.2-3.6 4.9-.1 2.2-1.3 4.6-5.2 3.7.2 2.1-1.7 3.9-2.7 3.8-.5 0-1.2-.2-2.1-.4-.8-.2 0-1.8.1-2.3.5-2.7-1.5-3.8-3.8-3.8-1.2-2.7-2.8-3.6-5.9-2.6-1.9-2.2-4.8-2.5-6.4.6.2-2.2-2.7-3.4-4.2-2.5' />
			<path d='M166.8 39.4L162.6 37.2' />
			<path d='M163.1 44.2L160.1 42.5' />
			<path d='M158.4 47.7L158.1 47.6' />
			<path d='M138.8 46.9L138.6 47.3' />
			<path d='M145.2 46.6L143.5 49.5' />
			<path d='M150.7 49.1L148.8 52.4' />
		</g>
	</svg>
)

export default React.memo(SvgComponent)
