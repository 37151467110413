import { CATALOG_SUGGESTION } from '../types'
import API from '../../utils/api'

const loading = () => {
	return {
		type: `${CATALOG_SUGGESTION}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${CATALOG_SUGGESTION}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${CATALOG_SUGGESTION}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const reset = (data) => {
	return {
		type: `${CATALOG_SUGGESTION}_RESET`,
		payload: {
			...data,
		},
	}
}

export const requestCatalogSuggestion = (query) => {
	if (query && query.length >= 3) {
		return async (dispatch) => {
			dispatch(loading())
			try {
				let response = await API({
					method: 'GET',
					url: `/catalogs/suggestion?q=${query}`,
				})

				if (
					response.data.data.category == null ||
					typeof response.data.data.category == 'undefined'
				) {
					response.data.data = {}
					response.data.data.category = []
				}
				dispatch(success(response.data))
			} catch (err) {
				err.data = {}
				err.data.category = []
				dispatch(failure(err))
			}
		}
	} else {
		return async (dispatch) => {
			let response = { data: { data: { category: [] } } }
			dispatch(reset(response.data))
		}
	}
}
