import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({color, hoverColor, activeColor, isHover, isActive, ...props}) => (
	<svg width={17.653} height={17.653} viewBox='0 0 17.653 17.653' {...props}>
		<path
			data-name='Icon material-account-box'
			d='M4.5 6.461v13.73a1.961 1.961 0 001.961 1.961h13.73a1.967 1.967 0 001.961-1.961V6.461a1.967 1.967 0 00-1.96-1.961H6.461A1.961 1.961 0 004.5 6.461zm11.769 3.923a2.942 2.942 0 11-2.942-2.942 2.938 2.938 0 012.942 2.942zM7.442 18.23c0-1.961 3.923-3.04 5.884-3.04s5.884 1.079 5.884 3.04v.981H7.442z'
			transform='translate(-4.5 -4.5)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
	</svg>
)

SvgComponent.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  isHover: PropTypes.bool,
  isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
  color: '#6c757e',
  hoverColor: '#6c757e',
  activeColor: '#6c757e',
  isHover: false,
  isActive: false,
}

export default SvgComponent
