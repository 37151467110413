import { LOGIN, LOGOUT, SET_REDIRECT } from '../types'
import { navigate } from '@reach/router'
import API from '../../utils/api'
import { persistor } from 'States/createStore'

export const loginAction = ({ user, pass, redirect }) => {
	return async (dispatch) => {
		try {
			const response = await dispatch({
				type: LOGIN,
				payload: API({
					method: 'POST',
					url: `/users/login?ts=${new Date().getTime()}`,
					data: {
						user,
						pass,
					},
				}),
			})
			if (response.value.data.data.message === 'Akun tidak Aktif') {
				throw new Error(response.value.data.data.message)
			} else if (response) {
				persistor.persist()
				if (redirect == null) {
					navigate('/dashboard')
				} else {
					setRedirectAction(null)
					navigate(redirect)
				}
			}
		} catch (err) {
			var errResponse = 'Username atau Kata Sandi Salah'
			if (
				typeof err.message !== 'undefined' &&
				err.message === 'Akun tidak Aktif'
			) {
				errResponse = 'Akun tidak Aktif'
			}
			dispatch({
				type: `${LOGIN}_REJECTED`,
				errResponse,
			})
		}
	}
}

export const resetAction = () => {
	return {
		type: `${LOGIN}_RESET`,
	}
}

export const logoutAction = () => {
	return async (dispatch) => {
		try {
			const response = await API({
				method: 'GET',
				url: `/users/logout?ts=${new Date().getTime()}`,
			})
			if (response) {
				const purge = await persistor.purge()
				if (purge) {
					dispatch({ type: 'CLEAR_STATE' })
				}
			}
		} catch (err) {
			dispatch({
				type: `${LOGOUT}_REJECTED`,
				err,
			})
		}
	}
}

export const setRedirectAction = (link) => {
	return async (dispatch) => {
		await dispatch({
			type: SET_REDIRECT,
			payload: link,
		})
		if (link != null) {
			navigate('/login')
		}
	}
}
