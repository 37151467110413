import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({ color, hoverColor, activeColor, isHover, isActive, ...props }) => {
	return (
		<svg 
			width={21.691}
			height={12.405}
			viewBox='0 0 21.691 12.405'
			{...props}
		>
			<path
				d='M14.733 64h-2.912a1.115 1.115 0 00-.732.271l-3.331 2.907-.01.013A1.242 1.242 0 007.676 69a1.465 1.465 0 001.9.087s.01 0 .014-.006l2.71-2.365a.564.564 0 01.766.032.5.5 0 01-.034.73l-.885.772 4.935 3.817a2.39 2.39 0 01.268.249v-6.249L15.5 64.3a1.1 1.1 0 00-.766-.3zm3.7 2.073V73.3a1.059 1.059 0 001.085 1.033h2.169v-8.26zm1.627 7.23a.517.517 0 11.542-.517.532.532 0 01-.538.514zM0 74.334h2.169A1.059 1.059 0 003.254 73.3v-7.227H0zm1.627-2.064a.517.517 0 110 1.033.517.517 0 110-1.033zm14.773.6l-5.06-3.914-1.017.888a2.53 2.53 0 01-3.446-.144 2.25 2.25 0 01.149-3.284L9.8 64H6.958a1.11 1.11 0 00-.766.3l-1.854 1.767V73.3h.62l3.067 2.645a2.243 2.243 0 003.05-.3l.007-.006.607.5a1.3 1.3 0 001.773-.174l1.064-1.246.183.142a1.12 1.12 0 001.525-.152l.322-.378a1 1 0 00-.156-1.456z'
				transform='translate(0 -64)'
				fill={(isActive && activeColor) || (isHover && hoverColor) || color}
			/>
		</svg>
	)
}

SvgComponent.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  isHover: PropTypes.bool,
  isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
  color: '#6c757e',
  hoverColor: '#6c757e',
  activeColor: '#6c757e',
  isHover: false,
  isActive: false,
}

export default SvgComponent
