import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      data-name="menunggu giliran"
      viewBox="0 0 41 41"
    >
      <g>
        <circle
          cx="20.5"
          cy="20.5"
          r="20.5"
          fill="#6c757e"
          data-name="Ellipse 95"
        ></circle>
      </g>
      <path
        fill="#fff"
        d="M4951-9224a10.928 10.928 0 01-7.779-3.221A10.927 10.927 0 014940-9235a11.013 11.013 0 0111-11 11.012 11.012 0 0111 11 10.929 10.929 0 01-3.222 7.778A10.925 10.925 0 014951-9224zm4.5-13a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 001.5 1.5 1.5 1.5 0 001.5-1.5 1.5 1.5 0 00-1.5-1.5zm-4 0a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 001.5 1.5 1.5 1.5 0 001.5-1.5 1.5 1.5 0 00-1.5-1.5zm-4 0a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 001.5 1.5 1.5 1.5 0 001.5-1.5 1.5 1.5 0 00-1.5-1.5z"
        data-name="Exclusion 1"
        transform="translate(-4929.999 9255.002)"
      ></path>
    </svg>
  );
}

export default Icon;
