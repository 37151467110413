import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <circle
        cx="20.5"
        cy="20.5"
        r="20.5"
        fill="#239d3c"
        data-name="Ellipse 106"
      ></circle>
      <g data-name="Group 776" transform="translate(10 9.353)">
        <g
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          data-name="Group 676"
        >
          <path
            d="M21.646 9.834v1A10.823 10.823 0 1115.228.942"
            data-name="Path 175"
          ></path>
          <path
            d="M21.646 2.327L10.045 13.939l-3.48-3.48"
            data-name="Path 176"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
