import React from "react"
import PropTypes from 'prop-types'

const SvgComponent = ({ color, hoverColor, activeColor, isHover, isActive, ...props }) => (
	<svg id="wheel" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22" {...props}>
		<path
			id="Path_130"
			data-name="Path 130"
			d="M138.558,133.609a4.949,4.949,0,1,0,4.949,4.949A4.955,4.955,0,0,0,138.558,133.609Zm0,8.66a3.712,3.712,0,1,1,3.712-3.712A3.716,3.716,0,0,1,138.558,142.27Zm0,0"
			transform="translate(-127.559 -127.559)"
			fill={(isActive && activeColor) || (isHover && hoverColor) || color} />
		<path id="Path_131"
			data-name="Path 131"
			d="M21.482,8.5l-1.691-.368a9.2,9.2,0,0,0-.544-1.312l.936-1.456a.658.658,0,0,0-.088-.82L17.461,1.9a.658.658,0,0,0-.82-.088l-1.456.936a9.2,9.2,0,0,0-1.312-.544L13.5.518A.657.657,0,0,0,12.863,0H9.137A.657.657,0,0,0,8.5.518L8.127,2.209a9.2,9.2,0,0,0-1.312.544L5.359,1.817a.658.658,0,0,0-.82.088L1.9,4.539a.658.658,0,0,0-.088.82l.936,1.456a9.2,9.2,0,0,0-.544,1.312L.518,8.5A.657.657,0,0,0,0,9.137v3.725a.657.657,0,0,0,.518.642l1.691.368a9.2,9.2,0,0,0,.544,1.312l-.936,1.456a.658.658,0,0,0,.088.82L4.539,20.1a.658.658,0,0,0,.82.088l1.456-.936a9.2,9.2,0,0,0,1.312.544L8.5,21.482A.657.657,0,0,0,9.137,22h3.725a.657.657,0,0,0,.642-.518l.368-1.691a9.2,9.2,0,0,0,1.312-.544l1.456.936a.657.657,0,0,0,.82-.088L20.1,17.461a.658.658,0,0,0,.088-.82l-.936-1.456a9.2,9.2,0,0,0,.544-1.312l1.691-.368A.657.657,0,0,0,22,12.863V9.137A.657.657,0,0,0,21.482,8.5Zm-.8,3.838-1.55.337a.658.658,0,0,0-.494.466,7.888,7.888,0,0,1-.728,1.757.657.657,0,0,0,.019.679l.859,1.335-1.885,1.885-1.336-.859a.657.657,0,0,0-.678-.019,7.892,7.892,0,0,1-1.757.728.658.658,0,0,0-.466.494l-.337,1.55H9.667l-.337-1.55a.658.658,0,0,0-.466-.494,7.888,7.888,0,0,1-1.757-.728.658.658,0,0,0-.679.019l-1.335.859L3.209,16.906l.858-1.335a.657.657,0,0,0,.02-.679,7.9,7.9,0,0,1-.728-1.757.657.657,0,0,0-.493-.466l-1.551-.337V9.667l1.55-.337a.658.658,0,0,0,.494-.466,7.887,7.887,0,0,1,.728-1.757.657.657,0,0,0-.019-.679L3.209,5.094,5.094,3.209l1.336.859a.657.657,0,0,0,.678.019,7.892,7.892,0,0,1,1.757-.728.658.658,0,0,0,.466-.494l.337-1.55h2.665l.337,1.55a.658.658,0,0,0,.466.494,7.887,7.887,0,0,1,1.757.728.658.658,0,0,0,.679-.019l1.335-.859,1.885,1.885-.858,1.335a.657.657,0,0,0-.02.679,7.889,7.889,0,0,1,.728,1.757.657.657,0,0,0,.493.466l1.551.337Zm0,0"
			fill={(isActive && activeColor) || (isHover && hoverColor) || color} />
	</svg>
)

SvgComponent.propTypes = {
	color: PropTypes.string,
	hoverColor: PropTypes.string,
	activeColor: PropTypes.string,
	isHover: PropTypes.bool,
	isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
	color: '#6c757e',
	hoverColor: '#6c757e',
	activeColor: '#6c757e',
	isHover: false,
	isActive: false,
}

export default SvgComponent