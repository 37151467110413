import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	container: {},
	parentPaper: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		marginTop: theme.spacing(1),
		'& ul': {
			position: 'relative',
			padding: 0,
			'&::before': {
				content: '""',
				clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
				background: theme.palette.common.white,
				width: 20,
				height: 10,
				display: 'inline-block',
				position: 'absolute',
				left: '13%',
				top: -9,
			},
		},
	},
	childFade: {
		height: '100%',
		transform: 'translate3d(379px, 0px, 0px)!important',
	},
	childPaper: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		height: '100%',
		minWidth: 300,
		overflowY: 'scroll',
		scrollbarWidth: 'thin',
	},
	menuItem: {
		'&:hover': {
			color: theme.palette.secondary.main,
			'& svg': {
				stroke: theme.palette.secondary.main,
			},
		},
		'&--parent': {
			borderBottom: '1px solid #eee',
			overflow: 'hidden',
			'& p': {
				marginLeft: theme.spacing(1),
			},
		},
	},
	buttonCategory: {
		color: theme.palette.common.white,
		textTransform: 'capitalize',
		'& p': {
			marginLeft: theme.spacing(1),
		},
	},
}))

export default styles
