import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <g>
        <circle
          cx="20.5"
          cy="20.5"
          r="20.5"
          fill="#081e3f"
          data-name="Ellipse 95"
        ></circle>
      </g>
      <g transform="translate(9 9)">
        <g data-name="Group 826">
          <g fill="#fff" data-name="Group 825">
            <path
              d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm0 22.957A10.957 10.957 0 1122.957 12 10.969 10.969 0 0112 22.957z"
              data-name="Path 382"
            ></path>
            <path
              d="M192.889 112.151a.522.522 0 00-.738.738l3.805 3.805-3.805 3.805a.522.522 0 10.738.738l4.174-4.174a.521.521 0 000-.738z"
              data-name="Path 383"
              transform="translate(-179.476 -104.694)"
            ></path>
            <path
              d="M117.063 116.325l-4.174-4.174a.522.522 0 00-.738.738l3.805 3.805-3.805 3.805a.522.522 0 10.738.738l4.174-4.174a.521.521 0 000-.738z"
              data-name="Path 384"
              transform="translate(-104.694 -104.694)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
