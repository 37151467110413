import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({color, hoverColor, activeColor, isHover, isActive, ...props}) => (
	<svg width={18.285} height={10.971} viewBox='0 0 18.285 10.971' {...props}>
		<path
			data-name='Icon awesome-money-bill'
			d='M17.371 4.5H.914A.914.914 0 000 5.414v9.142a.914.914 0 00.914.914h16.457a.914.914 0 00.914-.914V5.414a.914.914 0 00-.914-.914zm-16 9.6v-1.829A1.828 1.828 0 013.2 14.1zm0-6.4V5.871H3.2A1.828 1.828 0 011.371 7.7zm7.771 5.028a2.546 2.546 0 01-2.285-2.743 2.546 2.546 0 012.285-2.742 2.546 2.546 0 012.286 2.743 2.546 2.546 0 01-2.286 2.742zm7.771 1.372h-1.828a1.828 1.828 0 011.828-1.828zm0-6.4a1.828 1.828 0 01-1.828-1.828h1.828z'
			transform='translate(0 -4.5)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
	</svg>
)

SvgComponent.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  isHover: PropTypes.bool,
  isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
  color: '#6c757e',
  hoverColor: '#6c757e',
  activeColor: '#6c757e',
  isHover: false,
  isActive: false,
}

export default SvgComponent
