import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({color, hoverColor, activeColor, isHover, isActive, ...props}) => (
	<svg width={19.895} height={22.738} viewBox='0 0 19.895 22.738' {...props}>
		<path
			data-name='Icon metro-organization'
			d='M21.552 10.455H7.341A2.843 2.843 0 004.5 13.3v1.421a2.828 2.828 0 001.42 2.445v4.657h5.68v2.842h5.684v-2.842h5.684v-4.657a2.828 2.828 0 001.421-2.448V13.3a2.844 2.844 0 00-2.837-2.845zM10.183 20.4H7.341v-5.682H5.92V13.3a1.422 1.422 0 011.421-1.421h1.815a2.81 2.81 0 00-.394 1.421v2.842a2.828 2.828 0 001.421 2.448zm7.106-2.842v-2.84h-1.421v8.527h-2.843v-8.527H11.6v2.842a1.421 1.421 0 01-1.421-1.421V13.3a1.422 1.422 0 011.421-1.424h5.684A1.421 1.421 0 0118.71 13.3v2.842a1.42 1.42 0 01-1.421 1.421zm5.684-2.842h-1.421V20.4H18.71v-1.813a2.828 2.828 0 001.421-2.448V13.3a2.811 2.811 0 00-.394-1.421h1.815a1.421 1.421 0 011.421 1.421v1.421zM11.242 8.975a4.21 4.21 0 006.409 0 2.838 2.838 0 10.94-3.746 4.251 4.251 0 00-8.288 0 2.844 2.844 0 10.94 3.746zm8.889-2.784a1.421 1.421 0 11-1.421 1.421 1.42 1.42 0 011.421-1.421zm-5.685-2.842A2.842 2.842 0 1111.6 6.191a2.844 2.844 0 012.846-2.842zM8.762 9.034a1.421 1.421 0 111.421-1.421 1.421 1.421 0 01-1.421 1.421z'
			transform='translate(-4.499 -1.928)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
	</svg>
)

SvgComponent.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  isHover: PropTypes.bool,
  isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
  color: '#6c757e',
  hoverColor: '#6c757e',
  activeColor: '#6c757e',
  isHover: false,
  isActive: false,
}

export default SvgComponent
