import React from 'react'

const SvgComponent = props => (
	<svg width={115} height={115} viewBox='0 0 115 115' {...props}>
		<path
			data-name='circle-question-mark'
			d='M20.873 0C13.69 0 8.366 2.2 4.986 5.577A18.44 18.44 0 000 16.394l8.451 1.1a10.241 10.241 0 012.62-5.831c1.606-1.606 4.141-3.211 9.8-3.211 5.577 0 8.62 1.352 10.31 2.873a7.064 7.064 0 012.368 5.575c0 7.014-2.873 8.958-7.1 12.676s-9.8 9.127-9.8 19.014v2.11H25.1v-2.108c0-7.014 2.62-8.958 6.845-12.676S42 26.789 42 16.9a16.235 16.235 0 00-4.986-11.914C33.38 1.69 27.972 0 20.873 0zm-4.225 59.155v8.451H25.1v-8.451z'
			transform='translate(36 26)'
			fill={props.color || '#6c757e'}
		/>
		<g
			data-name='Ellipse 92'
			fill='none'
			stroke={props.color || '#6c757e'}
			strokeWidth={5}
		>
			<circle cx={57.5} cy={57.5} r={57.5} stroke='none' />
			<circle cx={57.5} cy={57.5} r={55} />
		</g>
	</svg>
)

export default SvgComponent
