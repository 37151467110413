import React from 'react'

const SvgComponent = props => (
	<svg
		x='0px'
		y='0px'
		viewBox='0 0 71.5 74.1'
		xmlSpace='preserve'
		{...props}
		strokeWidth='2'
		fill='none'
	>
		<g>
			<path d='M29.314 19.852c-4.426-.756-7.556 3.994-9.607 3.994-2.591 0-8.744-3.346-11.766-2.591-3.023.648-4.426 16.732-3.346 19.323 1.079 2.591 12.09 1.943 11.443 5.074-.648 2.915 1.295 4.75 4.966 4.318.864 1.943 2.483 2.915 5.182 2.915.216 1.943 3.994 3.562 5.397 3.023-.216 2.483 3.886 4.21 5.613 2.915.756-.54 1.079-1.079 1.511-1.835' />
			<path d='M60.403 43.169c1.727-.216 5.721-1.079 6.369-2.699 2.591-6.909-1.511-18.243-3.454-19.215-1.943-.864-7.233 2.051-10.903 2.267-4.102.324-9.5-6.477-13.062-6.477-2.699 0-7.664 3.886-8.636 5.829-.972 2.051-2.051 6.693-2.051 9.607 0 3.023 6.585 3.778 7.233 1.619.972-3.131 1.187-5.937 1.835-6.801.648-.864 2.159-2.267 3.994-1.079 8.096 5.182 16.3 8.96 16.948 10.255.864 1.943-1.187 5.074-3.238 4.642 1.619 2.159-.864 6.693-3.886 5.29-.108 2.375-1.403 4.966-5.613 3.994.216 2.267-1.835 4.21-2.915 4.102-.54 0-1.295-.216-2.267-.432-.864-.216 0-1.943.108-2.483.54-2.915-1.619-4.102-4.102-4.102-1.295-2.915-3.023-3.886-6.369-2.807-2.051-2.375-5.182-2.699-6.909.648.216-2.375-2.915-3.67-4.534-2.699' />
			<path d='M52.631 39.499L48.097 37.124' />
			<path d='M48.529 44.681L45.398 42.845' />
			<path d='M43.563 48.459L43.239 48.351' />
			<path d='M22.405 47.595L22.189 48.027' />
			<path d='M29.314 47.271L27.478 50.402' />
			<path d='M35.251 49.97L33.2 53.532' />
		</g>
	</svg>
)

export default React.memo(SvgComponent)
