/* eslint-disable prettier/prettier */
import { LIST_FILTER_CATEGORY } from '../../types'
import API from '../../../utils/api'
import { success, failure, loading } from '../../commonActions'
import { isEmpty } from 'lodash'

export const requestListFilterCategory = (categoryType = 'homepage-menu') => {
	return async (dispatch) => {
		dispatch(loading(LIST_FILTER_CATEGORY))
		const listFilterCategoryStorage = JSON.parse(sessionStorage.getItem('listFilterCategory'))
		let getFromStorage = false

		if (listFilterCategoryStorage) {
			let catDate = new Date(listFilterCategoryStorage.createdAt)
			let today = new Date()
			if (catDate.setHours(0,0,0,0) < today.setHours(0,0,0,0)) {
				getFromStorage = false
			} else {
				getFromStorage = true
			}
		}

		if (getFromStorage) {
			dispatch(success(LIST_FILTER_CATEGORY, listFilterCategoryStorage))
		} else {
			try {
				const response = await API({
					method: 'get',
					url: '/categories?categoryType=' + categoryType,
				})
				if (response.data) {
					if ('error_message' in response.data.data || isEmpty(response.data.data)) {
						dispatch(success(LIST_FILTER_CATEGORY, []))
					} else {
						dispatch(success(LIST_FILTER_CATEGORY, response.data))
						sessionStorage.setItem('listFilterCategory', JSON.stringify({ data: response.data.data, createdAt: new Date() }))
					}
				}
			} catch (err) {
				dispatch(failure(LIST_FILTER_CATEGORY, err))
			}
		}
	}
}
