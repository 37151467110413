import React from 'react'

const SvgComponent = props => (
	<svg
		x='0px'
		y='0px'
		viewBox='0 0 71.5 74.1'
		xmlSpace='preserve'
		strokeWidth='2'
		fill='none'
		{...props}
	>
		<g>
			<path d='M15.062 18.78L15.062 14.109 31.914 14.109 31.914 18.78' />
			<path d='M22.737 27.79L10.557 27.79 15.062 18.78 26.575 18.78' />
			<path d='M31.914 18.78L27.409 27.79' />
			<path d='M27.409 27.79L31.914 18.78 36.418 27.79z' />
			<path d='M27.409 27.79L27.409 59.991 10.557 59.991 10.557 27.79' />
			<path d='M27.409 27.79H36.586V59.991H27.409z' />
			<path d='M14.395 32.295H22.904V36.466H14.395z' />
		</g>
		<path d='M60.943 47.978V36.799c0-4.672-5.506-13.848-5.506-13.848-.334-.667-.167-1.502.501-2.002l.667-.501c.667-.501 1.168-1.335 1.168-2.169v-.167c0-.834-.667-1.502-1.502-1.502h-8.843c-.834 0-1.502.667-1.502 1.502v.501c0 .834.501 1.668 1.335 2.002l.501.334c.667.334 1.001 1.168.501 1.835 0 0-5.506 9.01-5.506 13.848v11.179' />
		<path d='M42.923 36.8L56.271 36.8' />
		<path d='M44.091 47.978L56.271 47.978' />
		<path d='M60.943 47.978L60.943 59.991 42.923 59.991 42.923 47.978' />
	</svg>
)

export default React.memo(SvgComponent)
