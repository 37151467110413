import React from "react"

const SvgComponent = props => (
	<svg x="0px" y="0px" width="76" height="68" viewBox="0 0 76 68" xmlSpace="preserve" {...props}>
		<defs>
			<clipPath id="b"><rect width="76" height="68"/>
			</clipPath>
		</defs>
		<g id="a" clip-path="url(#b)">
			<g transform="translate(-270.003 -62)">
				<path d="M45.1,58.869a16.891,16.891,0,0,1-.2-2A19.96,19.96,0,0,1,64.855,36.92a19.69,19.69,0,0,1,9.977,2.754V10.981A8,8,0,0,0,66.85,3H10.981A8,8,0,0,0,3,10.981V50.888a8,8,0,0,0,7.981,7.981ZM38.916,32.93,10.981,18.963V10.981L38.916,24.949,66.85,10.981v7.981Z" transform="translate(267.003 59)" fill="#acacac"/>
				<path d="M15.965,2A13.965,13.965,0,1,0,29.93,15.965,13.97,13.97,0,0,0,15.965,2ZM13.172,22.947,6.189,15.965,8.159,14l5.013,5,10.6-10.6,1.969,1.983Z" transform="translate(315.891 99.839)" fill="#28a745"/>
			</g>
		</g>
	</svg>
)


export default SvgComponent
