import React from 'react'

const SvgComponent = props => (
	<svg width={113.245} height={112.131} viewBox='0 0 113.245 112.131' {...props}>
		<g data-name='Group 625'>
			<g
				data-name='Group 676'
				fill='none'
				stroke={props.color || '#28a745'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth={6}
			>
				<path
					data-name='Path 175'
					d='M990.992 269.154v4.875a53 53 0 11-31.427-48.439'
					transform='translate(-881.99 -217.94)'
				/>
				<path
					data-name='Path 176'
					d='M990.992 232.397l-56.8 56.862-17.046-17.042'
					transform='translate(-881.99 -217.94)'
				/>
			</g>
		</g>
	</svg>
)

export default SvgComponent
