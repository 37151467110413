import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <circle
        cx="20.5"
        cy="20.5"
        r="20.5"
        fill="#ffc10a"
        data-name="Ellipse 93"
      ></circle>
      <g data-name="hourglass (1)" transform="translate(-48 10)">
        <g data-name="Group 769" transform="translate(61)">
          <path
            fill="#fff"
            d="M72.073 8.147a8.821 8.821 0 003.376-6.942h1.218V0H61v1.205h1.218a8.821 8.821 0 003.376 6.942c.915.834 1.432 1.344 1.432 2.137s-.518 1.3-1.432 2.137a8.821 8.821 0 00-3.376 6.942H61v1.205h15.667v-1.206h-1.218a8.821 8.821 0 00-3.376-6.942c-.915-.834-1.432-1.344-1.432-2.137s.517-1.302 1.432-2.136zM68.231 14.8a3.047 3.047 0 00-.549.169L63.991 16.5a8.221 8.221 0 012.415-3.185c.939-.856 1.825-1.664 1.825-3.028zm3.031-1.489a8.222 8.222 0 012.414 3.189l-3.691-1.527a3.047 3.047 0 00-.549-.169v-4.52c0 1.363.886 2.171 1.825 3.027zm-7.293-9.294a8.928 8.928 0 01-.545-2.812h10.818a8.928 8.928 0 01-.542 2.812z"
            data-name="Path 344"
            transform="translate(-61)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
