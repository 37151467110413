import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({color, hoverColor, activeColor, isHover, isActive, ...props}) => (
	<svg width={19.305} height={16.939} viewBox='0 0 19.305 16.939' {...props}>
		<path
			data-name='Path 146'
			d='M19.261 12.2a1.279 1.279 0 00-1.044-.926V3.937h.639a.32.32 0 00.32-.32V.421a.32.32 0 00-.32-.32H4.794a.32.32 0 00-.32.32v3.2a.32.32 0 00.32.32h.639v6.392h-.32a4.116 4.116 0 00-2.237.655V9.37a.32.32 0 00-.32-.32H.32a.32.32 0 00-.32.32v7.351a.32.32 0 00.32.32h2.237a.32.32 0 00.32-.32v-1.613a4.12 4.12 0 002.237.655h5.248a4.209 4.209 0 001.077-.142l6.92-1.854a1.283 1.283 0 00.902-1.567zM5.114.741h13.423V3.3H5.114zm.959 3.2h11.506v7.391l-3.709.995a1.285 1.285 0 00-.845-.742l-4.308-1.16a2.9 2.9 0 00-.743-.1h-1.9zM2.237 16.4H.639V9.69h1.6zm15.955-3.252L11.274 15a3.557 3.557 0 01-.912.12H5.114A3.492 3.492 0 013 14.42l-.128-.1v-2.553l.128-.1a3.49 3.49 0 012.109-.7h2.865a2.259 2.259 0 01.579.075l4.305 1.158a.645.645 0 01.47.55.312.312 0 00.013.039.315.315 0 00-.008.042.545.545 0 01-.021.154.639.639 0 01-.616.473.63.63 0 01-.167-.021l-2.717-.728a.32.32 0 10-.166.617l2.717.729a1.3 1.3 0 00.333.043 1.276 1.276 0 001.23-.939 1.145 1.145 0 00.036-.2l3.9-1.045c.028-.007.058-.014.086-.019a.639.639 0 01.7.47.624.624 0 01.021.166.639.639 0 01-.476.618zm0 0'
			transform='translate(0 -.102)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 147'
			d='M248.32 112.741h3.2a.32.32 0 000-.639h-3.2a.32.32 0 000 .639zm0 0'
			transform='translate(-238.092 -107.627)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
	</svg>
)

SvgComponent.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  isHover: PropTypes.bool,
  isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
  color: '#6c757e',
  hoverColor: '#6c757e',
  activeColor: '#6c757e',
  isHover: false,
  isActive: false,
}

export default SvgComponent
