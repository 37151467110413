import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({color, hoverColor, activeColor, isHover, isActive, ...props}) => (
	<svg width={17.927} height={17.927} viewBox='0 0 17.927 17.927' {...props}>
		<path
			data-name='Path 132'
			d='M145.157 217.157a1.158 1.158 0 001.156-1.157h-.578a.578.578 0 11-1.157 0H144a1.158 1.158 0 001.157 1.157zm0 0'
			transform='translate(-138.795 -208.193)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 133'
			d='M128.578 160.289a.289.289 0 11-.289-.289.289.289 0 01.289.289zm0 0'
			transform='translate(-123.374 -154.217)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 134'
			d='M208.578 160.289a.289.289 0 11-.289-.289.289.289 0 01.289.289zm0 0'
			transform='translate(-200.482 -154.217)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 135'
			d='M9.831 8.674v2.3l-1.446-.484v-.81a3.185 3.185 0 001.038-1.6A1.443 1.443 0 0010.7 6.65V4.337a4.337 4.337 0 00-8.674 0V6.65A1.443 1.443 0 003.3 8.079a3.185 3.185 0 001.038 1.6v.81l-2.953.985A2.021 2.021 0 000 13.394v2.8h9.831v1.735h8.1v-7.35l-1.9-1.9zm6.361.987l.748.748h-.748zm-7.562 1.52l-.441 2.2-1.382-.981 1.367-1.367zm.9-3.713a3.16 3.16 0 00.012-.24V5.836a.862.862 0 01-.012 1.632zM2.6 6.65a.866.866 0 01.578-.814v1.393c0 .081.006.16.012.24a.866.866 0 01-.59-.819zm.634-1.422A1.43 1.43 0 002.6 5.5V4.337a3.759 3.759 0 017.518 0V5.5a1.43 1.43 0 00-.634-.273A1.588 1.588 0 007 4.366l-.636.477-.636-.477a1.589 1.589 0 00-2.489.862zm.523 2v-1.59a1.012 1.012 0 011.62-.81l.983.737.983-.737a1.012 1.012 0 011.619.81v1.59a2.6 2.6 0 11-5.2 0zm2.6 3.181a3.16 3.16 0 001.446-.351v.52l-1.442 1.446-1.446-1.445v-.52a3.16 3.16 0 001.446.35zm-1.813.62L5.915 12.4l-1.382.987-.441-2.2zm-1.653 4.585v-1.735h-.578v1.735H.578v-2.22a1.444 1.444 0 01.989-1.371l1.973-.657.6 3.009 2.22-1.586 2.22 1.586.6-3.009.648.216v4.033zm7.518 1.735v-8.1h5.2v1.735h1.735v6.361zm0 0'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 136'
			d='M384 386.891h2.891V384H384zm.578-2.313h1.735v1.735h-1.735zm0 0'
			transform='translate(-370.121 -370.121)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 137'
			d='M304 448h2.313v.578H304zm0 0'
			transform='translate(-293.012 -431.808)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 138'
			d='M304 416h2.313v.578H304zm0 0'
			transform='translate(-293.012 -400.964)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 139'
			d='M304 384h2.313v.578H304zm0 0'
			transform='translate(-293.012 -370.121)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 140'
			d='M304 272h.578v.578H304zm0 0'
			transform='translate(-293.012 -262.169)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 141'
			d='M336 272h2.891v.578H336zm0 0'
			transform='translate(-323.856 -262.169)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 142'
			d='M304 304h4.048v.578H304zm0 0'
			transform='translate(-293.012 -293.012)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 143'
			d='M448 336h.578v.578H448zm0 0'
			transform='translate(-431.808 -323.856)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 144'
			d='M304 336h4.626v.578H304zm0 0'
			transform='translate(-293.012 -323.856)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
		<path
			data-name='Path 145'
			d='M323.181 54.361a3.176 3.176 0 002.588-5.023l.517-.564-.426-.391-.471.513a3.179 3.179 0 10-2.208 5.465zm0-5.783a2.592 2.592 0 011.818.743l-2.137 2.332-1.235-.988-.361.452 1.657 1.326 2.445-2.668a2.6 2.6 0 11-2.186-1.2zm0 0'
			transform='translate(-308.434 -46.265)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
	</svg>
)

SvgComponent.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  isHover: PropTypes.bool,
  isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
  color: '#6c757e',
  hoverColor: '#6c757e',
  activeColor: '#6c757e',
  isHover: false,
  isActive: false,
}

export default SvgComponent
