export const loading = (type) => {
	return {
		type: `${type}_PENDING`,
	}
}

export const failure = (type, error) => {
	return {
		type: `${type}_REJECTED`,
		payload: {
			...error,
		},
	}
}

export const success = (type, data) => {
	if(!data){
		return {
			type: `${type}_FULFILLED`
		}
	}
	return {
		type: `${type}_FULFILLED`,
		payload: {
			...data,
		},
	}
}
