import React from "react"

const SvgComponent = props => (
	<svg x="0px" y="0px" width="23.968" height="31.67" viewBox="0 0 23.968 31.67" xmlSpace="preserve" {...props}>
		<path id="Path_448" data-name="Path 448" d="M11.935,0a1.994,1.994,0,0,0-2,1.979V29.691a1.994,1.994,0,0,0,2,1.979H31.908a1.994,1.994,0,0,0,2-1.979V7.918L25.916,0Z" transform="translate(-9.938)" fill="#e2e7f0"/>
		<path id="Path_449" data-name="Path 449" d="M38.435,7.918h5.992L36.438,0V5.938A1.994,1.994,0,0,0,38.435,7.918Z" transform="translate(-20.458)" fill="#b0b7bd"/>
		<path id="Path_450" data-name="Path 450" d="M45.742,19.188,39.75,13.25h5.992Z" transform="translate(-21.774 -5.332)" fill="#cad1d8"/>
		<rect id="Rectangle_654" data-name="Rectangle 654" width="9" height="1.5" rx="0.75" transform="translate(6.698 9.835)" fill="#9dacba"/>
		<rect id="Rectangle_655" data-name="Rectangle 655" width="17" height="1.5" rx="0.75" transform="translate(2.698 13.835)" fill="#9dacba"/>
		<rect id="Rectangle_656" data-name="Rectangle 656" width="17" height="1.5" rx="0.75" transform="translate(2.698 16.835)" fill="#9dacba"/>
		<rect id="Rectangle_657" data-name="Rectangle 657" width="17" height="1.5" rx="0.75" transform="translate(2.698 19.835)" fill="#9dacba"/>
		<rect id="Rectangle_658" data-name="Rectangle 658" width="17" height="1.5" rx="0.75" transform="translate(2.698 22.835)" fill="#9dacba"/>
		<rect id="Rectangle_659" data-name="Rectangle 659" width="9" height="1.5" rx="0.75" transform="translate(2.698 25.835)" fill="#9dacba"/>
	</svg>
)


export default SvgComponent
