/* eslint-disable no-undef */
import axios from 'axios'

const apiUrl = process.env.GATSBY_API_URL + '/' + process.env.GATSBY_API_VERSION

export const apiErrorMessage = (err) => {
	let error = err.response
	let errorData = {
		data: {
			status: error.data.status,
			success: error.data.success,
			message: error.data.error.message,
		},
	}
	return errorData
}

const API = axios.create({
	baseURL: apiUrl,
	timeout: 30000,
	withCredentials: true,
})
API.defaults.headers.get['Cache-Control'] = 'no-cache, no-store'

export default API
