import React from "react"

const SvgComponent = props => (
	<svg x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
		<path
			d="M128 0c-17.6 0-32 14.4-32 32v448c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V128L352 0H128z"
			fill="#e2e5e7"
		/>
		<path d="M384 128h96L352 0v96c0 17.6 14.4 32 32 32z" fill="#b0b7bd" />
		<path fill="#cad1d8" d="M480 224L384 128 480 128z" />
		<path
			d="M416 416c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V256c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v160z"
			fill="#50bee8"
		/>
		<g fill="#fff">
			<path d="M92.576 384c-4.224 0-8.832-2.32-8.832-7.936v-72.656c0-4.608 4.608-7.936 8.832-7.936h29.296c58.464 0 57.168 88.528 1.136 88.528H92.576zm8.064-72.928v57.312h21.232c34.544 0 36.064-57.312 0-57.312H100.64zM228 385.28c-23.664 1.024-48.24-14.72-48.24-46.064 0-31.472 24.56-46.944 48.24-46.944 22.384 1.136 45.792 16.624 45.792 46.944 0 30.336-23.408 46.064-45.792 46.064zm-1.408-76.368c-14.336 0-29.936 10.112-29.936 30.32 0 20.096 15.616 30.336 29.936 30.336 14.72 0 30.448-10.24 30.448-30.336 0-20.224-15.728-30.32-30.448-30.32zM288.848 339.088c0-24.688 15.488-45.92 44.912-45.92 11.136 0 19.968 3.328 29.296 11.392 3.456 3.184 3.84 8.816.384 12.4-3.456 3.056-8.704 2.688-11.776-.384-5.232-5.504-10.608-7.024-17.904-7.024-19.696 0-29.152 13.952-29.152 29.552 0 15.872 9.328 30.448 29.152 30.448 7.296 0 14.08-2.96 19.968-8.192 3.952-3.072 9.456-1.552 11.76 1.536 2.048 2.816 3.056 7.552-1.408 12.016-8.96 8.336-19.696 10-30.336 10-30.944 0-44.896-21.136-44.896-45.824z" />
		</g>
		<path
			d="M400 432H96v16h304c8.8 0 16-7.2 16-16v-16c0 8.8-7.2 16-16 16z"
			fill="#cad1d8"
		/>
	</svg>
)


export default SvgComponent
