import React from "react"

const SvgComponent = props => (
	<svg x="0px" y="0px" width="14.88" height="14.88" viewBox="0 0 14.88 14.88" xmlSpace="preserve" {...props}>
		<g id="Icon_Download" data-name="Icon Download" transform="translate(1 1)">
			<g id="Icon_feather-download" data-name="Icon feather-download">
				<path id="Path_437" data-name="Path 437" d="M17.38,22.5v2.639a1.379,1.379,0,0,1-1.431,1.32H5.931A1.379,1.379,0,0,1,4.5,25.139V22.5" transform="translate(-4.5 -13.579)" fill="none" stroke="#007bff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
				<path id="Path_438" data-name="Path 438" d="M10.5,15l3.8,3.3L18.1,15" transform="translate(-7.861 -9.841)" fill="none" stroke="#007bff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
				<path id="Path_439" data-name="Path 439" d="M18,12.418V4.5" transform="translate(-11.56 -4.5)" fill="none" stroke="#007bff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
			</g>
  	</g>
	</svg>
)


export default SvgComponent
