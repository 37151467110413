import React from 'react'

const SvgComponent = props => (
	<svg
		id='Layer_1'
		x='0px'
		y='0px'
		viewBox='0 0 71.5 71.5'
		xmlSpace='preserve'
		fill='none'
		strokeWidth='2'
		{...props}
	>
		<g id='Group_154' data-name='Group 154' transform='translate(20 10)'>
			<path
				id='Path_102'
				data-name='Path 102'
				d='M-78.861 24.977a10.916 10.916 0 003.267 4.844 13.293 13.293 0 003.267-4.956c1.8-4.393-3.267-11.265-3.267-11.265s-4.956 6.984-3.267 11.377z'
				transform='translate(89.111 -13.6)'
			/>
			<path
				id='Path_103'
				data-name='Path 103'
				d='M-74.5 60.467a17.288 17.288 0 007.209-3.154c3.6-2.478 4.281-4.956 4.618-9.913a34.452 34.452 0 00-7.885 4.168 7.8 7.8 0 00-3.492 5.52'
				transform='translate(89.707 -9.326)'
			/>
			<path
				id='Path_104'
				data-name='Path 104'
				d='M-76.172 60.467a17.288 17.288 0 01-7.209-3.154c-3.6-2.478-4.281-4.956-4.618-9.913a34.452 34.452 0 017.885 4.168 7.8 7.8 0 013.492 5.52'
				transform='translate(88 -9.326)'
			/>
			<path
				id='Path_105'
				data-name='Path 105'
				d='M-74.5 48.967a17.288 17.288 0 007.209-3.154c3.6-2.478 4.281-4.956 4.618-9.913a34.452 34.452 0 00-7.885 4.168 7.8 7.8 0 00-3.492 5.52'
				transform='translate(89.707 -10.78)'
			/>
			<path
				id='Path_106'
				data-name='Path 106'
				d='M-76.172 48.967a17.288 17.288 0 01-7.209-3.154c-3.6-2.478-4.281-4.956-4.618-9.913a34.453 34.453 0 017.885 4.168 7.8 7.8 0 013.492 5.52'
				transform='translate(88 -10.78)'
			/>
			<path
				id='Path_107'
				data-name='Path 107'
				d='M-74.5 37.367a17.288 17.288 0 007.209-3.154c3.6-2.478 4.281-4.956 4.618-9.913a34.452 34.452 0 00-7.885 4.168 7.8 7.8 0 00-3.492 5.52'
				transform='translate(89.707 -12.247)'
			/>
			<path
				id='Path_108'
				data-name='Path 108'
				d='M-76.172 37.367a17.288 17.288 0 01-7.209-3.154c-3.6-2.478-4.281-4.956-4.618-9.913a34.453 34.453 0 017.885 4.168 7.8 7.8 0 013.492 5.52'
				transform='translate(88 -12.247)'
			/>
		</g>
	</svg>
)

export default React.memo(SvgComponent)
