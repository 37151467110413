import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({color, hoverColor, activeColor, isHover, isActive, ...props}) => (
	<svg width={21.937} height={25.07} viewBox="0 0 21.937 25.07" {...props}>
		<path
			d="M1.567 22.72a2.35 2.35 0 002.35 2.35h14.1a2.35 2.35 0 002.35-2.35V6.267h-18.8zm13.319-12.535a.783.783 0 011.567 0v10.968a.783.783 0 01-1.567 0zm-4.7 0a.783.783 0 011.567 0v10.968a.783.783 0 01-1.567 0zm-4.7 0a.783.783 0 011.567 0v10.968a.783.783 0 01-1.567 0zm15.667-8.618h-5.876l-.46-.916A1.175 1.175 0 0013.764 0h-5.6A1.161 1.161 0 007.12.651l-.46.916H.783A.783.783 0 000 2.35v1.567a.783.783 0 00.783.783h20.37a.783.783 0 00.783-.783V2.35a.783.783 0 00-.783-.783z"
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
	</svg>
)

SvgComponent.propTypes = {
	color: PropTypes.string,
	hoverColor: PropTypes.string,
	activeColor: PropTypes.string,
	isHover: PropTypes.bool,
	isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
	color: '#6c757e',
	hoverColor: '#6c757e',
	activeColor: '#6c757e',
	isHover: false,
	isActive: false,
}

export default SvgComponent
