import React from "react"

const SvgComponent = props => (
	<svg x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
      <path
        d="M128 0c-17.6 0-32 14.4-32 32v448c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V128L352 0H128z"
        fill="#e2e5e7"
      />
      <path d="M384 128h96L352 0v96c0 17.6 14.4 32 32 32z" fill="#b0b7bd" />
      <path fill="#cad1d8" d="M480 224L384 128 480 128z" />
      <path
        d="M416 416c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V256c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v160z"
        fill="#f15642"
      />
      <g fill="#fff">
        <path d="M105.456 303.152c0-4.224 3.328-8.832 8.688-8.832h29.552c16.64 0 31.616 11.136 31.616 32.48 0 20.224-14.976 31.488-31.616 31.488h-21.36v16.896c0 5.632-3.568 8.816-8.176 8.816-4.224 0-8.688-3.184-8.688-8.816v-72.032h-.016zm16.88 7.28v31.872h21.36c8.576 0 15.36-7.568 15.36-15.504 0-8.944-6.784-16.368-15.36-16.368h-21.36zM191.616 303.152c0-4.224 3.328-8.832 8.704-8.832h29.552c16.64 0 31.616 11.136 31.616 32.48 0 20.224-14.976 31.488-31.616 31.488h-21.36v16.896c0 5.632-3.584 8.816-8.192 8.816-4.224 0-8.704-3.184-8.704-8.816v-72.032zm16.88 7.28v31.872h21.36c8.576 0 15.36-7.568 15.36-15.504 0-8.944-6.784-16.368-15.36-16.368h-21.36zM301.68 311.472h-22.368c-11.136 0-11.136-16.368 0-16.368h60.496c11.392 0 11.392 16.368 0 16.368h-21.232v64.608c0 11.12-16.896 11.392-16.896 0v-64.608z" />
      </g>
      <path
        d="M400 432H96v16h304c8.8 0 16-7.2 16-16v-16c0 8.8-7.2 16-16 16z"
        fill="#cad1d8"
      />
    </svg>
)

export default SvgComponent