import React from 'react'

const SvgComponent = props => (
	<svg viewBox='0 0 70.52 56.416' {...props}>
		<path
			d='M54.653 24.682A15.867 15.867 0 1070.52 40.549a15.858 15.858 0 00-15.867-15.867zm7.047 16.56a1.073 1.073 0 01-1.07 1.07h-6.669a1.073 1.073 0 01-1.07-1.07V32.8a1.073 1.073 0 011.07-1.07h1.385a1.073 1.073 0 011.07 1.07v5.981h4.219a1.073 1.073 0 011.07 1.07zm-7.047-20.086c.6 0 1.181.036 1.763.089v-5.378a5.423 5.423 0 00-5.289-5.289h-8.815V5.289A5.423 5.423 0 0037.023 0h-17.63A5.423 5.423 0 0014.1 5.289v5.289H5.289A5.423 5.423 0 000 15.867v8.815h43.537a19.274 19.274 0 0111.116-3.526zM35.26 10.578h-14.1V7.052h14.1zm.751 24.682H22.919a1.763 1.763 0 01-1.763-1.76v-5.292H0v15.867a5.423 5.423 0 005.289 5.289H37.4a19.133 19.133 0 01-1.389-14.1z'
			fill='#081e3f'
		/>
	</svg>
)

export default SvgComponent
