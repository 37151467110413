import {
	rolePemohon as pemohon,
	rolePenyetuju as penyetuju,
	roleFinance as finance,
	roleAdmin as admin,
} from '../utils/acl'

export const ERROR_REPORT_TIMEOUT =
	'Mohon perkecil rentang pemilihan data dan ulangi proses muat data'
export const ERROR_REPORT_NO_DATA =
	'Dikarenakan tidak ada yang tersedia mohon ulangi pemilihan data Anda'
export const ERROR_REPORT_LIMIT =
	'Batas maksimal untuk memuat data adalah 1000 transaksi. Mohon perkecil rentang pemilihan anda'
export const ERROR_REPORT_TITLE = 'Tidak dapat memuat data'

export const rolesText = (type) => {
	let res = ''
	if (type == 'all') {
		res = `${pemohon},${penyetuju},${finance},${admin}`
	} else if (type == 'invoice') {
		res = `${admin},${penyetuju},${finance}`
	} else if (type == 'profile' || type == 'department') {
		res = `${admin},${penyetuju}`
	} else if (type == 'billing' || type == 'bank') {
		res = `${admin},${finance}`
	} else if (type == 'payment') {
		res = `${penyetuju},${finance}`
	} else if (type == 'admin') {
		res = `${admin}`
	} else if (type == 'pemohon') {
		res = `${pemohon}`
	} else if (type == 'finance') {
		res = `${finance}`
	} else {
		res = `${penyetuju}`
	}
	return res
}

export const addressTypesList = {
	data: [
		{ type: 'delivery', name: 'Alamat Pengiriman' },
		{ type: 'invoice', name: 'Alamat Tagihan' },
		{ type: 'other', name: 'Alamat Pengiriman & Tagihan' },
	],
}

export const emptyDropdown = 'Maaf, kata kunci tidak ditemukan'
