import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	iconSocial: {
		marginRight: '10px',
		marginLeft: '10px',
	},
	iconSocialLink: {
		color: theme.palette.common.white,
		textDecoration: 'none',
	},
	root: {
		color: theme.palette.common.white,
	},
	footerTitle: {
		marginBottom: theme.spacing(5),
		color: theme.palette.common.white,
		'& .wrapper-newsletter': {
			display: 'flex',
			alignItems: 'center',
			marginBottom: theme.spacing(1),
			'& .MuiTypography-body1': {
				marginLeft: theme.spacing(0.5),
				fontWeight: 600,
				letterSpacing: 1.2,
			},
		},
	},
	footerForm: {
		backgroundColor: theme.palette.common.white,
		display: 'flex',
		borderRadius: 5,
		overflow: 'hidden',
		'& button': {
			borderRadius: 0,
			textTransform: 'capitalize',
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.primary.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.secondary.main,
			},
		},
	},
	footerFormInput: {
		padding: theme.spacing(0, 1),
		flex: 1,
	},
	footerContact: {
		alignContent: 'center',
		'& svg+span,svg+svg': {
			marginLeft: 10,
		},
		'& p+div': {
			marginTop: 5,
		},
		'& div+div': {
			marginTop: 5,
		},
		'& .grid-container': {
			gridTemplateColumns: 'repeat(2, 1fr)',
			[theme.breakpoints.only('xs')]: {
				gridTemplateColumns: '1fr',
			},
		},
		'& button': {
			[theme.breakpoints.only('xs')]: {
				marginBottom: theme.spacing(3),
			},
		},
	},
	footerUri: {
		textDecoration: 'none',
		color: '#357ab6',
	},
	footerContactItem: {
		cursor: 'pointer',
		padding: '0px 5px 0px 0px',
	},
	mt_25: {
		marginTop: '25px',
	},
	footerTitleMenu: {
		color: '#BBBBBB',
		fontWeight: 600,
	},
}))

export default useStyles
