import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({color, hoverColor, activeColor, isHover, isActive, ...props}) => (
	<svg width={17.265} height={13.812} viewBox='0 0 17.265 13.812' {...props}>
		<path
			data-name='Icon material-payment'
			d='M18.538 6H4.726a1.713 1.713 0 00-1.717 1.726L3 18.085a1.72 1.72 0 001.726 1.726h13.812a1.72 1.72 0 001.726-1.726V7.726A1.72 1.72 0 0018.538 6zm0 12.085H4.726v-5.179h13.812zm0-8.632H4.726V7.726h13.812z'
			transform='translate(-3 -6)'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
	</svg>
)

SvgComponent.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  isHover: PropTypes.bool,
  isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
  color: '#6c757e',
  hoverColor: '#6c757e',
  activeColor: '#6c757e',
  isHover: false,
  isActive: false,
}

export default SvgComponent
