import React from 'react'
import PropTypes from 'prop-types'
import Header from '../header'
import Footer from '../footer'
import Styles from './styles'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { Container, Box } from '@material-ui/core'
// import { ImgComponentFixed } from '../image'
import SEO from '../seo'
// import ScrollToTop from '../scrollToTop'

const sliderOpts = {
	dots: false,
	infinite: false,
	slidesToShow: 6,
	slidesToScroll: 2,
	nextArrow: <Arrow />,
	prevArrow: <Arrow />,
	responsive: [
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
}

function Arrow(props) {
	// eslint-disable-next-line react/prop-types
	const { className, style, onClick } = props
	return (
		<div
			className={className}
			style={{
				...style,
				backgroundColor: '#ffff',
				boxShadow: '0.5px 1px 2px rgba(0,0,0, 0.2)',
				borderRadius: 50,
				padding: 5,
				width: 'auto',
				height: 'auto',
				color: '#343A40',
			}}
			onClick={onClick}
		/>
	)
}

const LayoutComponent = ({
	children,
	header,
	newsletter,
	className,
	withMedia,
	variant,
	isFooter,
}) => {
	const classes = Styles()
	return (
		<Box className={className}>
			<SEO />

			{header && <Header />}

			<Container className={classes.container}>{children}</Container>
			{withMedia && (
				<Box marginTop={2.5} className={classes.mediaContainer}>
					<Container>
						<Slider {...sliderOpts}>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='178'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='179'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='180'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='181'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='182'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='183'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='184'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='185'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='186'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='187'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
							<div className={classes.sliderItem}>
								<ins
									data-revive-zoneid='188'
									data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
								></ins>
							</div>
						</Slider>
					</Container>
				</Box>
			)}

			{isFooter ? (
				<Footer
					variant={variant}
					newsletter={newsletter}
					className={classes.footers}
				/>
			) : null}

			{/* <ScrollToTop /> */}
		</Box>
	)
}

LayoutComponent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	header: PropTypes.bool,
	newsletter: PropTypes.bool,
	className: PropTypes.string,
	withMedia: PropTypes.bool,
	variant: PropTypes.string,
	isFooter: PropTypes.bool,
}

LayoutComponent.defaultProps = {
	header: true,
	newsletter: true,
	withMedia: false,
	variant: 'dark',
	isFooter: true,
}

export default LayoutComponent
