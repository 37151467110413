import { USER_PROFILE } from '../../types'
import API from '../../../utils/api'
import { success, failure, loading } from '../../commonActions'

export const getUserProfileDetail = (id) => {
	return async (dispatch) => {
		dispatch(loading(USER_PROFILE))
		try {
			let response = await API({
				method: 'GET',
				url: '/users/detail/' + id,
			})

			dispatch(success(USER_PROFILE, response.data))
		} catch (err) {
			dispatch(failure(USER_PROFILE, err))
		}
	}
}
