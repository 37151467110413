import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({color, hoverColor, activeColor, isHover, isActive, ...props}) => (
	<svg width={13.331} height={17.774} viewBox='0 0 13.331 17.774' {...props}>
		<path
			data-name='Icon awesome-file-invoice-dollar'
			d='M13.088 3.645l-3.4-3.4A.833.833 0 009.1 0h-.213v4.444h4.444v-.212a.831.831 0 00-.243-.587zM7.776 4.721V0H.833A.831.831 0 000 .833v16.108a.831.831 0 00.833.833H12.5a.831.831 0 00.833-.833V5.554H8.609a.836.836 0 01-.833-.833zM2.222 2.5a.278.278 0 01.278-.278h2.777a.278.278 0 01.278.278v.555a.278.278 0 01-.278.278H2.5a.278.278 0 01-.278-.278zm0 2.777v-.556a.278.278 0 01.278-.277h2.777a.278.278 0 01.278.278v.555a.278.278 0 01-.278.278H2.5a.278.278 0 01-.278-.278zm5 9.161v.837a.278.278 0 01-.278.278h-.556a.278.278 0 01-.278-.278v-.843a1.988 1.988 0 01-1.089-.394.278.278 0 01-.021-.422l.408-.389a.285.285 0 01.352-.025.836.836 0 00.445.129h.976a.437.437 0 00.41-.458.453.453 0 00-.3-.442l-1.562-.469a1.571 1.571 0 01-1.1-1.506A1.546 1.546 0 016.11 8.891v-.837a.278.278 0 01.278-.278h.555a.278.278 0 01.278.278V8.9a1.985 1.985 0 011.089.394.278.278 0 01.02.421l-.408.389a.285.285 0 01-.352.025.833.833 0 00-.445-.129h-.976a.437.437 0 00-.41.458.453.453 0 00.3.442l1.562.469a1.571 1.571 0 011.1 1.506 1.546 1.546 0 01-1.48 1.562z'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
	</svg>
)

SvgComponent.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  isHover: PropTypes.bool,
  isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
  color: '#6c757e',
  hoverColor: '#6c757e',
  activeColor: '#6c757e',
  isHover: false,
  isActive: false,
}

export default SvgComponent
