import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import { decryptAES } from './cryptojs'

export const rolePemohon = 'staff'
export const rolePenyetuju = 'manager'
export const roleFinance = 'finance'
export const roleAdmin = 'admin'

export const checkRole = (roles, shouldNavigate) => {
	if (roles.length == 0) {
		if (shouldNavigate) {
			navigate('/401')
		}
		return false
	}
	let routeRoles = roles.split(',')
	let myRoles = useSelector((state) => state.auth.data.data.user_roles)
	myRoles = decryptAES(myRoles)
	myRoles = myRoles
		.replace('[', '')
		.replace(']', '')
		.replace(/"/g, '')
		.split(',')
	let arrayLength = myRoles.length
	let allowedAccess = false
	for (let i = 0; i < arrayLength; i++) {
		if (routeRoles.includes(myRoles[i])) {
			allowedAccess = true
			break
		}
	}
	if (!allowedAccess) {
		if (shouldNavigate) {
			navigate('/401')
		}
		return false
	}

	return true
}

export const acl = (userRoles, roles) => {
	let _userRoles = userRoles
	let routeRoles = roles.split(',')
	_userRoles = decryptAES(userRoles)
	_userRoles = _userRoles
		.replace('[', '')
		.replace(']', '')
		.replace(/"/g, '')
		.split(',')
	let arrayLength = _userRoles.length
	let allowedAccess = false
	for (let i = 0; i < arrayLength; i++) {
		if (routeRoles.includes(_userRoles[i])) {
			allowedAccess = true
			break
		}
	}
	return allowedAccess
}
