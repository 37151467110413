import {
	ADD_TO_CART,
	REMOVE_FROM_CART,
	LIST_CART_ITEM,
	COUNT_CART_ITEM,
	SET_MODAL,
	UPDATE_CART_ITEM,
	SAVE_CART_ID,
	DELETE_ALL_CART_ITEMS,
	RESET_LIST_CART_ITEM,
} from '../types'
import API from '../../utils/api'
import { setGlobalLoading } from './globalAction'
import { checkIsAvetti } from 'Utilities/globalUtils'
import { navigate } from 'gatsby'

const loading = (type) => {
	return {
		type: `${type}_PENDING`,
	}
}

const failure = (type, error) => {
	return {
		type: `${type}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (type, data) => {
	return {
		type: `${type}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const successListCart = (type, data) => {
	return {
		type: `${type}_FULFILLED`,
		payload: {
			data: [...data],
		},
	}
}

const filterListCart = ({ id, cart_item }) => {
	let filterItem = []
	cart_item
		.filter((item) => item.status === 99)
		.map((item) =>
			filterItem.push({
				cart_id: id,
				id: item.id,
				sku_id: item.sku_id,
				product_id: item.product_id,
				qty: item.product_uom_qty,
				cart_item_id: item.id,
				taxes: item.product_data.taxes_id,
				measureType: item.product_data.uom_id.name,
				image_512_filestore: item.product_data.image_512_filestore,
				variant: item.product_data.product_variant_ids,
				total: item.total,
				is_price_updated: item.is_price_updated,
				is_product_deleted: item.is_product_deleted,
				is_stock_empty: item.is_stock_empty,
			})
		)
	return new Promise((resolve) => resolve(filterItem))
}

export const requestListCartItem = (withNavigate = false) => {
	return async (dispatch, getState) => {
		dispatch(loading(LIST_CART_ITEM))
		const isEmbeded = checkIsAvetti()
		let url = '/carts/items?ts=' + new Date().getTime()
		const authData = getState().auth.data.data
		const { company_buyer_id, id } = authData

		if (isEmbeded) url = `/carts/avetti/list/${company_buyer_id}/${id}`
		try {
			const response = await API({
				method: 'GET',
				url,
			})
			if (response.data.data[0].cart_item) {
				const filteredItem = await filterListCart(response.data.data[0])
				if (filteredItem) {
					dispatch(successListCart(LIST_CART_ITEM, filteredItem))
				}
			} else {
				dispatch(successListCart(LIST_CART_ITEM, []))
			}

			if (withNavigate) {
				navigate('/dashboard/rfq/form')
			}

			// filter variant
		} catch (err) {
			dispatch(failure(LIST_CART_ITEM, err))
		}
	}
}

export const addToCartAction = ({
	product_id,
	product_name,
	notes,
	qty,
	sku_id,
}) => {
	return async (dispatch, getState) => {
		dispatch(loading(ADD_TO_CART))
		const isEmbeded = checkIsAvetti()
		let url = '/carts/add-item'
		const authData = getState().auth.data.data
		const { company_buyer_id, id } = authData

		if (isEmbeded) url = `/carts/avetti/add/${company_buyer_id}/${id}`
		try {
			const response = await API({
				method: 'POST',
				url,
				data: {
					product_id,
					product_name,
					notes,
					qty,
					sku_id,
				},
			})
			dispatch(success(ADD_TO_CART, response.data))
			dispatch(requestListCartItem())
			dispatch(countCartItem())
			dispatch(modalSuccess(true))
		} catch (err) {
			dispatch(failure(ADD_TO_CART, err))
		}
	}
}

export const countCartItem = () => {
	return async (dispatch, getState) => {
		dispatch(loading(COUNT_CART_ITEM))
		const isEmbeded = checkIsAvetti()
		let url = `/carts/count?ts=${new Date().getTime()}&status=99`
		const authData = getState().auth.data.data
		const { company_buyer_id, id } = authData

		if (isEmbeded) url = `/carts/avetti/count/${company_buyer_id}/${id}`
		try {
			const response = await API({
				method: 'GET',
				url,
			})
			dispatch(success(COUNT_CART_ITEM, response.data))
		} catch (err) {
			dispatch(failure(COUNT_CART_ITEM, err))
		}
	}
}

export const deleteAllItem = (cartID) => {
	return async (dispatch, getState) => {
		const authData = getState().auth.data.data
		const { company_buyer_id, id } = authData
		dispatch(setGlobalLoading(true))
		try {
			const response = await API({
				method: 'DELETE',
				url: `/carts/avetti/delete/${cartID}/${company_buyer_id}/${id}`,
			})
			dispatch(success(DELETE_ALL_CART_ITEMS, response.data))
			dispatch(requestListCartItem())
		} catch (e) {
			dispatch(setGlobalLoading(false))
		}
	}
}

export const removeFromCartAction = (id_to_delete) => {
	return async (dispatch) => {
		dispatch(loading(REMOVE_FROM_CART))
		const isEmbeded = checkIsAvetti()
		let url = '/carts/items/' + id_to_delete

		if (isEmbeded) url = `/carts/avetti/item/${id_to_delete}`
		try {
			const response = await API({
				method: 'DELETE',
				url,
			})
			dispatch(success(REMOVE_FROM_CART, response.data))
			dispatch(countCartItem())
			// dispatch(modalSuccess(true))
			// dispatch(requestListCartItem())
			// dispatch(modalSuccess(false))
		} catch (err) {
			dispatch(failure(REMOVE_FROM_CART, err))
		}
	}
}

export const SaveCartID = (item) => {
	return {
		type: SAVE_CART_ID,
		payload: {
			item,
		},
	}
}

const updateCartItem = (type, data) => {
	return {
		type,
		payload: {
			...data,
		},
	}
}

export const updateCartAction = (cart_items, cart_id) => {
	return async (dispatch, getState) => {
		dispatch(loading(UPDATE_CART_ITEM))
		const isEmbeded = checkIsAvetti()
		let url = '/carts/update/' + cart_id
		const authData = getState().auth.data.data
		const { company_buyer_id, id } = authData
		let navigate = true

		if (isEmbeded) {
			url = `/carts/avetti/update/${cart_id}/${company_buyer_id}/${id}`
			navigate = false
		}
		try {
			const response = await API({
				method: 'PUT',
				url,
				data: {
					cart_items,
				},
			})

			dispatch(updateCartItem(UPDATE_CART_ITEM, response.data))
			dispatch(SaveCartID(cart_items))
			dispatch(modalSuccess(false))
			if (!isEmbeded) dispatch(requestListCartItem(navigate))
		} catch (err) {
			dispatch(failure(UPDATE_CART_ITEM, err))
		}
	}
}

export const modalSuccess = (val) => {
	return {
		type: SET_MODAL,
		payload: val,
	}
}

export const resetAction = () => {
	return {
		type: RESET_LIST_CART_ITEM,
	}
}
