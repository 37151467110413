import React from 'react'

const SvgComponent = props => (
	<svg width={179.814} height={171.121} viewBox='0 0 179.814 171.121' {...props}>
		<g fill={props.color || '#6c757e'}>
			<path
				data-name='Path 48'
				d='M159.823 60.622l18.963-18.963a3.512 3.512 0 00-.793-5.562L155.2 23.578a3.512 3.512 0 00-3.38 6.157L170.511 40l-16.275 16.274L95.7 24.132l16.276-16.273 16.45 9.034a3.512 3.512 0 003.38-6.157L113.046.433a3.511 3.511 0 00-4.173.6l-18.965 18.96L70.943 1.029a3.513 3.513 0 00-4.173-.6L1.822 36.1a3.512 3.512 0 00-.793 5.562l18.964 18.96L1.029 79.584a3.512 3.512 0 00.793 5.562l19.626 10.777v36.023a3.514 3.514 0 001.822 3.08l64.947 35.662a3.511 3.511 0 003.38 0l64.947-35.662a3.514 3.514 0 001.822-3.08V95.923l19.626-10.776a3.512 3.512 0 00.793-5.562zM89.908 92.276L32.257 60.62l57.65-31.657 57.65 31.657zM67.841 7.859l16.272 16.273-58.534 32.142L9.306 40zM25.582 64.968l58.534 32.141-16.275 16.275L9.306 81.242zm125.761 64.9L93.42 161.674v-35.9a3.512 3.512 0 10-7.024 0v35.9l-57.924-31.806V99.78l38.3 21.029a3.511 3.511 0 004.173-.6l18.963-18.959 18.965 18.965a3.511 3.511 0 004.173.6l38.3-21.029zm-39.367-16.484L95.7 97.108l58.534-32.14 16.276 16.273zm0 0'
				transform='translate(-.001)'
			/>
			<path
				data-name='Path 49'
				d='M398.313 55.2a3.515 3.515 0 10-2.483-1.03 3.543 3.543 0 002.483 1.03zm0 0'
				transform='translate(-.001) translate(-256.146 -31.257)'
			/>
			<path
				data-name='Path 50'
				d='M249.512 314.926a3.513 3.513 0 102.488 1.029 3.534 3.534 0 00-2.488-1.029zm0 0'
				transform='translate(-.001) translate(-159.604 -204.324)'
			/>
		</g>
	</svg>
)

export default SvgComponent
