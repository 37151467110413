import DateFnsAdapter from '@date-io/date-fns'
import { id as IDN } from 'date-fns/locale'
import { format as formatDate } from 'date-fns'
import moment from 'moment'
import API from './api'
import {
	AVETTI_LOCAL,
	AVETTI_PROD,
	AVETTI_STAGING,
	AVETTI_UAT,
} from '../constants/url'
import { decryptAES } from './cryptojs'
import { addressTypesList } from '../constants/text'

export const newUriAPI = (queryString) => {
	function generateQuery(key, value) {
		let keyUri = ''
		if (key == 'per_page') {
			keyUri = 'page_size'
		} else {
			keyUri = key
		}

		return keyUri + '=' + value
	}

	return Object.keys(queryString)
		.map((key) => generateQuery(key, queryString[key]))
		.filter((key) => {
			return key != '' || queryString[key] != ''
		})
		.join('&')
}

export const newUri = (queryString) => {
	return Object.keys(queryString)
		.map((key) => key + '=' + queryString[key])
		.filter((key) => {
			return key != '' || queryString[key] != ''
		})
		.join('&')
}

export const dateFormat = (date, format) => {
	if (date == '' || typeof date == 'undefined') return date
	const dateFns = new DateFnsAdapter()
	const initialDateFnsDate = dateFns.date(date)

	return dateFns.format(initialDateFnsDate, format)
}

export const dateFormatFromOdoo = (dateStr, format, isUsingTime) => {
	if (dateStr == '' || typeof dateStr == 'undefined') return dateStr

	let dateString = ''
	let arrDate = dateStr.split('-')

	if (arrDate[0] == '') return dateStr

	dateString = isUsingTime
		? `${arrDate[0]}-${arrDate[1]}-${arrDate[2]}T${arrDate[3]}:${arrDate[4]}:00Z`
		: `${arrDate[0]}-${arrDate[1]}-${arrDate[2]}`

	const dateFns = new DateFnsAdapter()
	const initialDateFnsDate = dateFns.date(dateString)

	return formatDate(initialDateFnsDate, format, { locale: IDN })
}

export const containsString = (target, pattern) => {
	if (typeof target == 'undefined' || target == null) {
		return false
	}
	var value = 0
	pattern.forEach(function (word) {
		value = value + target.includes(word)
	})

	return value > 0
}

export const capitalizeFirst = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const arrObjectToStr = (arr, key, separator) => {
	if (typeof arr == 'undefined' || arr == null || arr.length == 0) {
		return ''
	}

	return arr
		.map((item) => {
			let value = item[key]
			if (item[key] == 'manager') {
				value = 'penyetuju'
			}
			if (item[key] == 'staff') {
				value = 'pemohon'
			}
			return capitalizeFirst(value)
		})
		.join(separator)
}

export const toRoleId = (value) => {
	let role_id
	switch (value.toLowerCase()) {
		case 'admin':
			role_id = 6
			break
		case 'aggreement': // this is manager
			role_id = 7
			break
		case 'finance':
			role_id = 8
			break
		case 'staff': // this is requestor (pemohon)
			role_id = 9
			break
		default:
			role_id = ''
	}
	return role_id
}

export const findMissingNumber = (inputAr) => {
	// Sort array
	sortArray(inputAr)

	// finding missing number here
	var result = 0
	if (inputAr[0] > 1 || inputAr[inputAr.length - 1] < 1) {
		result = 1
	} else {
		for (var i = 0; i < inputAr.length; i++) {
			if (inputAr[i + 1] - inputAr[i] > 1) {
				result = inputAr[i] + 1
			}
		}
	}
	if (!result) {
		result = inputAr[inputAr.length - 1] + 1
	}
	return result
}

export const sortArray = (inputAr) => {
	var temp
	for (var i = 0; i < inputAr.length; i++) {
		for (var j = i + 1; j < inputAr.length; j++) {
			if (inputAr[j] < inputAr[i]) {
				temp = inputAr[j]
				inputAr[j] = inputAr[i]
				inputAr[i] = temp
			}
		}
	}
}

export const filterUserApproval = (listUser) => {
	// listUser = array of list user

	if (!listUser && listUser.length == 0) {
		return []
	}

	var result = listUser.filter(function (item) {
		let user_buyer_roles = item.user_buyer_roles
		user_buyer_roles = user_buyer_roles.filter(function (roles) {
			return roles.role_id == 7
		})
		return user_buyer_roles.length > 0
	})

	return result
}

export const generateAddress = (address, withCountry = false) => {
	if (address) {
		if (address.partner_shipping_id) {
			address = address.partner_shipping_id
		}

		let compiledAddress = ''
		if (address.street) {
			compiledAddress += address.street
		}
		if (address.street2) {
			compiledAddress += ' ' + address.street2
		}
		if (address.sub_district.name) {
			compiledAddress += ', ' + address.sub_district.name
		}
		if (address.district.name) {
			compiledAddress += ', ' + address.district.name
		}
		if (address.city) {
			compiledAddress += ', ' + address.city
		}
		if (address.state_id.name) {
			compiledAddress += ', ' + address.state_id.name
		}
		if (address.zip) {
			compiledAddress += ', ' + address.zip
		}
		if (withCountry) compiledAddress += ', ' + address.country_id.name
		return compiledAddress
	}

	return ''
}

export const generateAddressCompany = (address) => {
	let companyAddress = ''
	if (address) {
		const { street, street2, city, country_id, state_id, zip, partner_id } =
			address
		if (street) companyAddress += street
		if (street2) companyAddress += ' ' + street2
		if (partner_id.sub_district.name)
			companyAddress += ', ' + partner_id.sub_district.name
		if (partner_id.district.name)
			companyAddress += ', ' + partner_id.district.name
		if (city) companyAddress += ', ' + city
		if (state_id.name) companyAddress += ', ' + state_id.name
		if (zip) companyAddress += ', ' + zip
		if (country_id.name) companyAddress += ', ' + country_id.name
	}

	return companyAddress
}

export const generateAddressType = (type) => {
	const result = addressTypesList.data.filter((item) => item.type === type)
	return result[0].name || ''
}

export const checkDepartment = async (departmentId) => {
	const response = await API.put(`/companies/departments/check/${departmentId}`)
	return new Promise((resolve, reject) => {
		if (response.data.data.can_action) resolve(true)
		else reject(false)
	})
}

export const checkUser = async (userID) => {
	try {
		const response = await API.put(`/users/check/${userID}`)
		return response.data
	} catch (err) {
		return err
	}
}

export const getFileExtension = (filename) => {
	return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined
}

export const isBrowser = () => typeof window !== 'undefined'

export const getUrl = () => {
	if (isBrowser()) {
		const { host } = window.location
		if (host.includes('.web.id')) {
			return AVETTI_STAGING
		} else if (host.includes('.my.id')) {
			return AVETTI_UAT
		} else if (host.includes('.loc')) {
			return AVETTI_LOCAL
		}
		return AVETTI_PROD
	}
}

export const chunkSizeArray = (data, chunkSize) => {
	let res = []

	for (let i = 0; i < data.length; i += chunkSize) {
		let chunk = data.slice(i, i + chunkSize)
		res.push(chunk)
	}

	return res
}

export const sortData = (data) => {
	let res = data.sort((a, b) =>
		a.index > b.index ? 1 : b.index > a.index ? -1 : 0
	)
	return res
}

export const checkIsAvetti = () => {
	if (localStorage.getItem('avetti')) {
		const avettiData = JSON.parse(decryptAES(localStorage.getItem('avetti')))
		if (avettiData.valid) {
			return true
		}
	}
	return false
}

export const validateUpload = (maxSize, type, data) => {
	let res = {
		isError: false,
		textError: '',
		textErrorMultiple: [],
	}
	if (data) {
		let checkType =
			type && type.length != 0 ? type.find((x) => x == data.type) : []

		if (data.size > maxSize) {
			res.textError = 'Ukuran file melebihi batas maksimal'
			res.isError = true
			res.textErrorMultiple.push(
				'Ukuran dokumen yang Anda masukkan melebihi batas maksimal'
			)
		}
		if (!checkType) {
			res.textError = 'Format file yang Anda masukkan tidak sesuai'
			res.isError = true
			res.textErrorMultiple.push('Format dokumen yang Anda masukkan tidak sesuai')
		}
	}

	return res
}

export const convertBytesToString = (byteData, isUsingWord) => {
	let res = ''
	if (byteData >= 1048576) {
		res = isUsingWord
			? (byteData / 1048576).toFixed(2) + ' MB'
			: (byteData / 1048576).toFixed(2)
	} else if (byteData >= 1024) {
		res = isUsingWord
			? (byteData / 1024).toFixed(2) + ' KB'
			: (byteData / 1024).toFixed(2)
	} else if (byteData > 1) {
		res = isUsingWord ? byteData + ' bytes' : byteData
	} else if (byteData == 1) {
		res = isUsingWord ? byteData + ' byte' : byteData
	} else {
		res = isUsingWord ? '0 bytes' : byteData
	}
	return res
}

export const removeItemArray = (arr, index) => {
	arr.splice(index, 1)
	return arr
}

export const listExtensions = [
	'xls',
	'xlsx',
	'doc',
	'docx',
	'dotx',
	'ppt',
	'pptx',
	'pdf',
	'jpg',
	'jpeg',
	'png',
	'gif',
	'jpe',
]

export const listExt = {
	excel: ['xls', 'xlsx'],
	doc: ['doc', 'docx', 'dotx'],
	ppt: ['ppt', 'pptx'],
	pdf: ['pdf'],
	image: ['jpg', 'jpeg', 'png', 'gif', 'jpe'],
}

export const sortArraybyId = (data) => {
	return data.sort(function (a, b) {
		return a.id > b.id ? 1 : -1
	})
}

export const sortArrayNumber = (data) => {
	return data.sort(function (a, b) {
		return a - b
	})
}

export const filterData = (tagSuggest, val) => {
	let res = tagSuggest.find((item) => item.id === val.id)
	return res
}

const escapeForRegExp = (string) => {
	return string.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
}

export const matchAny = (string) => {
	return new RegExp(escapeForRegExp(string), 'gi')
}

export const markIt = (name, query) => {
	const regexp = matchAny(query)
	return name.replace(regexp, '<b>$&</b>')
}

//function check input is only number or not
export const isNumberInput = (value) => {
	const re = /^[0-9\b]+$/
	return re.test(value)
}

export const isValidDate = (date) => {
	const m = moment(date, 'YYYY-MM-DD')
	return m.isValid()
}

export const isAlphabetInput = (value) => {
	const regex = /^[a-zA-Z\s]+$/
	return regex.test(value)
}

export const isAlphabetPuctuationRegex = (value) => {
	// eslint-disable-next-line
	const regex = /^[a-zA-Z\s\-\+\/\.\,]+$/
	return regex.test(value)
}
export const isNumberPuctuationRegex = (value) => {
	// eslint-disable-next-line
	const regex = /^[0-9\s\+\-\/\.\,]+$/
	return regex.test(value)
}

export const isCommonButton = (event, window) => {
	let theEvent = event || window.event
	let key = theEvent.keyCode || theEvent.which

	// Don't validate the input if below arrow, delete and backspace keys were pressed
	let usedButton =
		key == 37 ||
		key == 38 ||
		key == 39 ||
		key == 40 ||
		key == 8 ||
		key == 46 ||
		event.metaKey
	if (usedButton) {
		return true
	}
	return false
}

export const camelCaseToSnakeCase = (property) => {
	return property.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase())
}
