import React from 'react'
import useStyles from './styles'
import { navigate } from '@reach/router'
import {
	Container,
	Grid,
	Typography,
	InputBase,
	Button,
	Box,
} from '@material-ui/core'
import {
	Mail,
	Phone,
	Telegram,
	Facebook,
	LinkedIn,
	YouTube,
	// Instagram,
} from '@material-ui/icons'
import { styled } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Link as GLink } from 'gatsby'

const getYear = () => {
	let date = new Date()
	let year = date.getFullYear()
	return year
}

const Footer = styled('footer')(({ theme }) => ({
	position: 'relative',
	bottom: 0,
	width: '100%',
	backgroundColor: theme.palette.primary.main,
	paddingTop: theme.spacing(3),
	paddingBottom: theme.spacing(3),
}))

const Footer_copyright = styled('footer')(({ theme, variant }) => ({
	borderTop: variant === 'dark' ? '1px solid #fff' : '',
	position: 'relative',
	bottom: 0,
	width: '100%',
	// backgroundColor:theme.palette.primary.main,
	backgroundColor:
		variant === 'dark' ? theme.palette.primary.main : theme.palette.common.white,
	paddingTop: '1rem!important',
	paddingBottom: '1rem!important',
	textAlign: 'center',
	color: variant === 'dark' ? '#fff' : '#a2a2a2',
}))
const FooterComponent = ({ newsletter, variant }) => {
	const classes = useStyles()
	return (
		<Box>
			{newsletter && (
				<Footer className={classes.root}>
					<Container>
						<Grid container justify='center' className={classes.footerTitle}>
							<Grid
								container
								justify='center'
								alignItems='center'
								direction='column'
								item
								lg={8}
							>
								<div className='wrapper-newsletter'>
									<Mail />
									<Typography variant='h6'>Newsletter</Typography>
								</div>
								<Typography
									align='center'
									gutterBottom
									variant='caption'
									style={{ marginBottom: 15 }}
								>
									Daftarkan alamat email anda sekarang untuk mendapatkan penawaran
									spesial dari mbiz.co.id
								</Typography>
							</Grid>
							<Grid item lg={5} md={8} sm={10} xs={12}>
								<div className={classes.footerForm}>
									<InputBase
										placeholder='Masukkan Alamat Email Anda'
										className={classes.footerFormInput}
									/>
									<Button
										className={classes.footerFormButton}
										disableElevation
										size='small'
										variant='contained'
									>
										Berlangganan
									</Button>
								</div>
							</Grid>
						</Grid>
						<Grid container justify='center'>
							<Grid
								item
								container
								justify='center'
								xs={6}
								sm={3}
								md={3}
								lg={3}
								direction='column'
								className={classes.footerContact}
							>
								<Typography variant='body1' className={classes.footerTitleMenu}>
									HUBUNGI KAMI
								</Typography>
								<Box display='flex' alignItems='center'>
									<Phone fontSize='small' />
									<Typography className={classes.footerContactItem} variant='caption'>
										021 400 01 036
									</Typography>
								</Box>
								<Box display='flex' alignItems='center'>
									<Mail fontSize='small' />
									<Typography className={classes.footerContactItem} variant='caption'>
										help@mbiz.co.id
									</Typography>
								</Box>
								<Box display='flex' alignItems='center'>
									<Telegram fontSize='small' />
									<Typography className={classes.footerContactItem} variant='caption'>
										<GLink className={classes.iconSocialLink} to='/contact-us'>
											Kirim Pesan
										</GLink>
									</Typography>
								</Box>
							</Grid>
							<Grid
								item
								container
								xs={6}
								sm={3}
								md={3}
								lg={3}
								direction='column'
								className={classes.footerContact}
							>
								<Typography variant='body1' className={classes.footerTitleMenu}>
									MBIZ.CO.ID
								</Typography>
								<Box display='flex' alignItems='center'>
									<Typography className={classes.footerContactItem} variant='caption'>
										<GLink className={classes.iconSocialLink} to='/pages/about'>
											Tentang Kami
										</GLink>
									</Typography>
								</Box>
								<Box display='flex' alignItems='center'>
									<Typography className={classes.footerContactItem} variant='caption'>
										<a
											href='https://insight.mbiz.co.id/'
											rel='noopener noreferrer'
											target='_blank'
											style={{ color: '#fff', textDecoration: 'none' }}
										>
											Blog Mbiz
										</a>
									</Typography>
								</Box>
								<Box display='flex' alignItems='center'></Box>
							</Grid>
							<Grid
								item
								container
								xs={6}
								sm={3}
								md={3}
								lg={3}
								direction='column'
								className={classes.footerContact}
							>
								<Typography variant='body1' className={classes.footerTitleMenu}>
									SOLUSI B2B
								</Typography>
								<Box display='flex' alignItems='center'>
									<Typography className={classes.footerContactItem} variant='caption'>
										<GLink
											className={classes.iconSocialLink}
											to='/pages/id/privacy-policy'
										>
											Kebijakan Privasi
										</GLink>
									</Typography>
								</Box>
								<Box display='flex' alignItems='center'>
									<Typography className={classes.footerContactItem} variant='caption'>
										<GLink
											className={classes.iconSocialLink}
											to='/pages/id/term-and-condition'
										>
											Syarat dan Ketentuan
										</GLink>
									</Typography>
								</Box>
							</Grid>
							<Grid
								item
								container
								xs={6}
								sm={3}
								md={3}
								lg={3}
								direction='column'
								className={classes.footerContact}
							>
								<Typography variant='body1' className={classes.footerTitleMenu}>
									TRANSAKSI MBIZ
								</Typography>
								<Box display='flex' alignItems='center'>
									<Typography
										onClick={() => navigate('/categories')}
										className={classes.footerContactItem}
										variant='caption'
									>
										<GLink className={classes.iconSocialLink} to='/categories'>
											Telusuri Kategori
										</GLink>
									</Typography>
								</Box>
								<Box display='flex' alignItems='center'>
									<Typography className={classes.footerContactItem} variant='caption'>
										<GLink className={classes.iconSocialLink} to='/pages/how-to'>
											Cara Penggunaan
										</GLink>
									</Typography>
								</Box>
								<Box display='flex' alignItems='center'>
									<Typography className={classes.footerContactItem} variant='caption'>
										<GLink className={classes.iconSocialLink} to='/pages/id/complaint'>
											Kebijakan komplain
										</GLink>
									</Typography>
								</Box>
							</Grid>
						</Grid>
						<Grid container justify='center'>
							<Grid
								className={classes.mt_25}
								direction='column'
								container
								justify='center'
								alignItems='center'
								item
								lg={8}
							>
								<Box>
									<a
										className={classes.iconSocialLink}
										href='https://www.facebook.com/Mbizcoid/'
										target='_blank'
										rel='noopener noreferrer'
									>
										<Facebook className={classes.iconSocial} />
									</a>
									<a
										className={classes.iconSocialLink}
										href='https://www.linkedin.com/company/mbiz-co-id'
										target='_blank'
										rel='noopener noreferrer'
									>
										<LinkedIn className={classes.iconSocial} />
									</a>
									<a
										className={classes.iconSocialLink}
										href='https://www.youtube.com/channel/UCf-w8kKUdJODDVVHcFevP5Q'
										target='_blank'
										rel='noopener noreferrer'
									>
										<YouTube className={classes.iconSocial} />
									</a>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Footer>
			)}

			<Footer_copyright variant={variant}>
				Copyright{' '}
				<a href='/' className={classes.footerUri}>
					Mbiz.co.id
				</a>{' '}
				© {getYear()}. All right reserved.
			</Footer_copyright>
		</Box>
	)
}

FooterComponent.propTypes = {
	newsletter: PropTypes.bool,
	variant: PropTypes.string,
}

FooterComponent.defaultProps = {
	variant: 'dark',
}

export default FooterComponent
