import React from 'react'
import {
	MenuList,
	Popper,
	Paper,
	ClickAwayListener,
	MenuItem,
	Button,
	Fade,
	Typography,
} from '@material-ui/core'
import { Cat10 } from '../svgs'
import { FormatListBulleted } from '@material-ui/icons'
import Styles from './styles'
import PropTypes from 'prop-types'
// import { navigate } from '@reach/router'
import { getMappingIcon } from '../../utils/category'

const CascaderCategory = ({ options }) => {
	const classes = Styles()
	const [open, setOpen] = React.useState(false)
	const [chOpen, setChOpen] = React.useState(false)
	const anchorRef = React.useRef(null)
	const [childCat, setChildCat] = React.useState(null)
	const pRef = React.useRef(null)

	// const CascaderCategoryData = options.data ? options.data : null

	const icons_map = getMappingIcon({ width: '30px', height: '30px' })

	const handleOpen = () => {
		if (!options.data.length) return false
		setOpen((prevOpen) => !prevOpen)
		setChOpen(false)
	}

	const handleClose = () => {
		setOpen(false)
		setChOpen(false)
	}
	const onMouseDown = (slug) => {
		window.location.href = `/catalog/${slug}`
		// navigate(`/catalog/${slug}`)
	}
	const onMouseEnter = (items) => {
		if (!items.child_category?.length) {
			setChOpen(false)
		}
		setChildCat(items.child_category)
		if (chOpen || !items.child_category.length) return false
		setChOpen(items.id)
	}

	const prevOpen = React.useRef(open)

	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus()
		}
		prevOpen.current = open
	}, [open])

	return (
		<div style={{ position: 'relative' }}>
			<Button
				className={classes.buttonCategory}
				ref={anchorRef}
				aria-haspopup='true'
				aria-controls={open ? 'menu-list-grow' : undefined}
				onClick={handleOpen}
			>
				<FormatListBulleted />
				<Typography variant='body1'>Kategori</Typography>
			</Button>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				placement='bottom-start'
				style={{
					transform: 'translate3d(0px, 39px, 0px)',
				}}
			>
				{({ TransitionProps }) => (
					<Fade
						{...TransitionProps}
						style={{
							transformOrigin: 'bottom',
						}}
					>
						<Paper ref={pRef} className={classes.parentPaper}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList autoFocusItem={open} id='menu-list-grow'>
									{options.data.map((item) => (
										<div key={item.id}>
											<MenuItem
												className={`${classes.menuItem} ${classes.menuItem}--parent`}
												onClick={handleClose}
												onMouseEnter={() => onMouseEnter(item)}
												onMouseDown={() => onMouseDown(item.slug)}
												aria-haspopup='true'
												aria-controls={open ? 'menu-list-child' : undefined}
											>
												{icons_map ? (
													icons_map[item.slug] ? (
														icons_map[item.slug]
													) : (
														<Cat10 stroke='#081E3F' width='35px' height='30px' />
													)
												) : (
													<Cat10 stroke='#081E3F' width='35px' height='30px' />
												)}

												<Typography component='p' variant='body1'>
													{item.category_name}
												</Typography>
											</MenuItem>
											{!item.child_category?.length ? null : (
												<Popper
													open={chOpen === item.id}
													anchorEl={pRef.current}
													role={undefined}
													transition
													disablePortal
													placement='right-start'
													className={classes.childFade}
												>
													{(va) => (
														<Fade {...va.TransitionProps} className={classes.childPaper}>
															<Paper>
																<MenuList
																	autoFocusItem={chOpen === item.id}
																	id='menu-list-child'
																>
																	{childCat?.map((val) => (
																		<MenuItem
																			className={classes.menuItem}
																			key={val.id}
																			onClick={handleClose}
																			onMouseDown={() => onMouseDown(val.slug)}
																		>
																			{val.category_name}
																		</MenuItem>
																	))}
																</MenuList>
															</Paper>
														</Fade>
													)}
												</Popper>
											)}
										</div>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Fade>
				)}
			</Popper>
		</div>
	)
}

CascaderCategory.propTypes = {
	options: PropTypes.object,
}

export default CascaderCategory
