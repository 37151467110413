import React from 'react'

const SvgComponent = props => (
	<svg viewBox='0 0 74.111 64.843' {...props}>
		<path
			data-name='Icon open-account-login'
			d='M27.79 0v9.263h37.053V55.58H27.79v9.263h46.321V0zm9.263 18.527v9.263H0v9.263h37.053v9.263l18.527-13.9z'
			fill='#081e3f'
		/>
	</svg>
)

export default SvgComponent
