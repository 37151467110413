import React from 'react'

const SvgComponent = props => (
	<svg width={108} height={108} viewBox='0 0 108 108' {...props}>
		<path
			d='M48.2 20.392H31.514V3.708A3.708 3.708 0 0027.807 0H24.1a3.708 3.708 0 00-3.708 3.708v16.684H3.708A3.708 3.708 0 000 24.1v3.708a3.708 3.708 0 003.708 3.708h16.684V48.2a3.708 3.708 0 003.708 3.706h3.708a3.708 3.708 0 003.706-3.706V31.514H48.2a3.708 3.708 0 003.708-3.708V24.1a3.708 3.708 0 00-3.708-3.708z'
			transform='rotate(45 6.056 74)'
			fill={props.color || '#dc3545'}
		/>
		<g
			data-name='Ellipse 10'
			fill='none'
			stroke={props.color || '#dc3545'}
			strokeWidth={8}
		>
			<circle cx={54} cy={54} r={54} stroke='none' />
			<circle cx={54} cy={54} r={50} />
		</g>
	</svg>
)

export default SvgComponent
