import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { navigate } from '@reach/router'
import { LogoWhite } from '../svgs'
import logoApl from '../../assets/images/svgs/whitelabel/logoApl.svg'
import logoUnilever from '../../assets/images/svgs/whitelabel/logoUnilever.svg'
import logoCombiphar from '../../assets/images/svgs/whitelabel/logoCombiphar.svg'
import logoSampoerna from '../../assets/images/svgs/whitelabel/logoSampoerna.svg'
import {
	SearchRounded,
	ShoppingCart,
	Person,
	Menu,
	PowerSettingsNew,
} from '@material-ui/icons'
import {
	Container,
	Box,
	IconButton,
	Hidden,
	Grid,
	Backdrop,
	TextField,
	Typography,
	Badge,
	MenuItem,
	Paper,
	Avatar,
	Popper,
	MenuList,
	Grow,
	ClickAwayListener,
} from '@material-ui/core'
import useStyles from './styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CascaderCategory from '../cascaderCategory/component'
import { requestCatalogSuggestion } from 'States/actions/catalogSuggestionAction'
import { requestListFilterCategory } from 'States/actions/Category/listFilterCategoryAction'
import { logoutAction } from 'States/actions/authAction'
import { countCartItem } from 'States/actions/cartAction'
import * as qs from 'query-string'
import { getUserProfileDetail } from 'States/actions/UsersAccount/userProfileAction'

const HeaderComponent = () => {
	const inputRef = useRef()
	const classes = useStyles()
	// state
	const dispatch = useDispatch()
	const [searchTerm, setSearchTerm] = useState('')
	const [show, setShow] = useState(false)
	const [catalogSuggestion, setCatalogSuggestion] = useState([])
	const [showMenu, setShowMenu] = useState(false)
	const queryString = qs.parse(window.location.search)
	// end state

	const cartData = useSelector((state) => state.cart.countCart, shallowEqual)
	const isLoggedIn = useSelector((state) => state.auth.loggedIn)
	const listFilterCat = useSelector((state) => state.listFilterCategoryReducer)
	const [dataListFitlerCat, setDataListFitlerCat] = useState({ data: [] })
	const auth = useSelector((state) => state.auth)
	const catalogSuggestionReducer = useSelector(
		(state) => state.catalogSuggestionReducer
	)
	const userProfile = useSelector((state) => state.userProfileReducer)

	const defaultCatalogSuggestion = [
		{
			name: 'Semua Kategori',
			slug: 'semua-kategori',
			typeCategory: 'all',
		},
	]

	const submitSearch = () => {
		inputRef.current.blur()
		window.location.href = `/catalog/search?q=${searchTerm}`
		setShow(false)
	}

	const handleEnter = (e) => {
		if (e.key === 'Enter') {
			submitSearch()
		}
	}

	useEffect(() => {
		dispatch(requestCatalogSuggestion(''))
	}, [])

	useEffect(() => {
		if (queryString && queryString.q) {
			setSearchTerm(queryString.q)
		}
	}, [queryString.q])

	useEffect(() => {
		if (listFilterCat?.data?.length == 0) {
			dispatch(requestListFilterCategory())
		}
	}, [listFilterCat.data])

	useEffect(() => {
		if (listFilterCat.isLoading == false && listFilterCat.data != null) {
			setDataListFitlerCat(listFilterCat)
		}
	}, [listFilterCat.isLoading, listFilterCat.data])

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(getUserProfileDetail(auth.data.data.id))
			dispatch(countCartItem())
		}
	}, [dispatch, isLoggedIn])

	// hooks for set default state
	useEffect(() => {
		let tmpDefaultCatalogSuggestion = defaultCatalogSuggestion
		if (searchTerm && searchTerm.length >= 3) {
			if (
				catalogSuggestionReducer.data &&
				catalogSuggestionReducer.data.category
			) {
				tmpDefaultCatalogSuggestion.push(...catalogSuggestionReducer.data.category)
			}
			setCatalogSuggestion(tmpDefaultCatalogSuggestion)
		} else if (searchTerm && searchTerm.length == 2) {
			setCatalogSuggestion(defaultCatalogSuggestion)
		} else {
			setCatalogSuggestion([])
		}
	}, [catalogSuggestionReducer, searchTerm])

	const handleChange = (value) => {
		if (!value) value = ''
		setSearchTerm(value)
		dispatch(requestCatalogSuggestion(value))
	}

	const handleRenderOption = (option, inputValue) => {
		return (
			<div>
				<span key={option.slug}>
					<strong>{inputValue}</strong> dalam {option.name}
				</span>
			</div>
		)
	}

	const filterOptions = (options) => options

	const handleNavigate = (path) => {
		navigate(path)
	}

	const handleRedirect = (path) => {
		// window.location.href = path
		navigate(path)
	}

	const handleLogout = async () => {
		dispatch(logoutAction())
	}

	const optionLabel = () => {
		return searchTerm || queryString.q || ''
	}

	// const anchorRef = React.useRef(null)
	const [anchorEl, setAnchorEl] = React.useState(null)

	const delayHandler = React.useRef(null)
	delayHandler.current = null

	const open = Boolean(anchorEl)

	const handleToggleMenu = (event) => {
		clearTimeout(delayHandler.current)
		setAnchorEl(event.currentTarget)
	}

	const handleMouseLeave = () => {
		delayHandler.current = setTimeout(() => {
			setAnchorEl(null)
		}, 200)
	}

	const handleCloseMenu = (path) => (event) => {
		if (path) {
			handleNavigate(path)
		}
		setAnchorEl(anchorEl ? null : event.currentTarget)
	}

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault()
		}
	}

	const getSubdomainIcon = () => {
		let arrDomain =
			typeof window !== 'undefined' ? window.location.hostname.split('.') : ''
		switch (arrDomain[0]) {
			case 'apl':
				return (
					<Box position='relative' left='12px'>
						<Avatar src={logoApl} />
					</Box>
				)
			case 'combiphar':
				return (
					<Box position='relative' left='12px'>
						<Avatar src={logoCombiphar} />
					</Box>
				)
			case 'sampoerna':
				return (
					<Box position='relative' left='12px'>
						<Avatar src={logoSampoerna} />
					</Box>
				)
			case 'unilever':
				return (
					<Box position='relative' left='12px'>
						<Avatar src={logoUnilever} />
					</Box>
				)
			default:
				return null
		}
	}

	const renderPropfile = () => {
		if (isLoggedIn) {
			return (
				<Box>
					<IconButton
						onMouseLeave={handleMouseLeave}
						onMouseEnter={handleToggleMenu}
						className={classes.iconButton}
					>
						<Avatar
							alt={
								userProfile.isLoading == false && userProfile.data
									? userProfile.data.name
									: null
							}
							src='/src/assets/images/icons/profile.png'
						></Avatar>
					</IconButton>
					<Popper
						className={classes.popper}
						open={open}
						anchorEl={anchorEl}
						role={undefined}
						transition
						disablePortal
						onMouseLeave={handleMouseLeave}
						onMouseEnter={handleToggleMenu}
						// placement='bottom-end'
						style={{
							top: '37px',
							left: '67.7%',
							position: 'absolute',
							transform: 'translate3d(0px, 39px, 0px)',
						}}
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<Paper>
									<ClickAwayListener onClickAway={handleCloseMenu}>
										<MenuList
											autoFocusItem={open}
											id='menu-list-grow'
											onKeyDown={handleListKeyDown}
											className={classes.customMenu}
										>
											<MenuItem className={classes.borderMenu}>
												<Box width={300}>
													<Typography variant='subtitle1'>
														{userProfile.isLoading == false && userProfile.data
															? userProfile.data.name
															: null}
													</Typography>
													<Typography variant='body2'>
														{auth.isLoading == false && auth.data.data.company_details
															? auth.data.data.company_details.name
															: null}
													</Typography>
												</Box>
											</MenuItem>
											<MenuItem
												onClick={handleCloseMenu('/dashboard')}
												className={classes.borderMenu}
											>
												Dasbor
											</MenuItem>
											<MenuItem
												onClick={handleCloseMenu('/dashboard/profile')}
												className={classes.borderMenu}
											>
												Lihat Profil
											</MenuItem>
											{/* <MenuItem
												onClick={handleCloseMenu('/profile/change-password')}
												className={classes.borderMenu}
											>
												Ubah Kata Sandi
											</MenuItem> */}
											<MenuItem
												onClick={() => handleLogout()}
												className={classes.borderMenu}
											>
												<PowerSettingsNew
													style={{ color: '#ccc', fontWeight: 'bold' }}
													fontSize='small'
												/>{' '}
												Keluar
											</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Box>
			)
		}
		return (
			<Box
				className={classes.headerMenu__item}
				onClick={() => handleNavigate('/login')}
			>
				<Person fontSize='large' />
				<Box ml={1} display='flex' flexDirection='column'>
					<Typography variant='caption'>Selamat Datang</Typography>
					<Typography variant='caption'>Masuk / Daftar</Typography>
				</Box>
			</Box>
		)
	}

	const renderSmallMenu = () => {
		if (isLoggedIn) {
			return (
				<Box>
					<IconButton
						onMouseLeave={handleMouseLeave}
						onMouseEnter={handleToggleMenu}
						className={classes.iconButton}
					>
						<Avatar
							alt={
								userProfile.isLoading == false && userProfile.data
									? userProfile.data.name
									: null
							}
							src='/src/assets/images/icons/profile.png'
						></Avatar>
					</IconButton>
					<Popper
						className={classes.popper}
						open={open}
						anchorEl={anchorEl}
						role={undefined}
						transition
						disablePortal
						onMouseLeave={handleMouseLeave}
						onMouseEnter={handleToggleMenu}
						// placement='bottom-end'
						style={{
							top: '37px',
							left: '67.7%',
							position: 'absolute',
							transform: 'translate3d(0px, 39px, 0px)',
						}}
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<Paper>
									<ClickAwayListener onClickAway={handleCloseMenu}>
										<MenuList
											autoFocusItem={open}
											id='menu-list-grow'
											onKeyDown={handleListKeyDown}
											className={classes.customMenu}
										>
											<MenuItem className={classes.borderMenu}>
												<Box width={300}>
													<Typography variant='subtitle1'>
														{userProfile.isLoading == false && userProfile.data
															? userProfile.data.name
															: null}
													</Typography>
													<Typography variant='body2'>
														{auth.isLoading == false && auth.data.data.company_details
															? auth.data.data.company_details.name
															: null}
													</Typography>
												</Box>
											</MenuItem>
											<MenuItem
												onClick={handleCloseMenu('/dashboard')}
												className={classes.borderMenu}
											>
												Dasbor
											</MenuItem>
											<MenuItem
												onClick={handleCloseMenu('/dashboard/profile')}
												className={classes.borderMenu}
											>
												Lihat Profil
											</MenuItem>
											{/* <MenuItem
												onClick={handleCloseMenu('/profile/change-password')}
												className={classes.borderMenu}
											>
												Ubah Kata Sandi
											</MenuItem> */}
											<MenuItem
												onClick={() => handleLogout()}
												className={classes.borderMenu}
											>
												<PowerSettingsNew
													style={{ color: '#ccc', fontWeight: 'bold' }}
													fontSize='small'
												/>{' '}
												Keluar
											</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Box>
			)
		}
		return (
			<>
				<Menu
					className={classes.headerMenu__item_icon}
					onClick={() => setShowMenu(true)}
				/>

				<Popper
					open={showMenu}
					transition
					disablePortal
					style={{
						top: '37px',
						position: 'absolute',
						left: 'auto',
						transform: 'translateX(-34px)',
					}}
				>
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<Paper>
								<ClickAwayListener onClickAway={() => setShowMenu(false)}>
									<MenuList
										autoFocusItem={showMenu}
										id='menu-list-grow'
										className={classes.customMenu}
									>
										<MenuItem
											className={classes.borderMenu}
											onClick={() => handleNavigate('/login')}
										>
											<Box>
												<Typography variant='caption'>Masuk / Daftar</Typography>
											</Box>
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</>
		)
	}

	return (
		<>
			<Backdrop
				open={show}
				className={classes.backdrop}
				onClick={() => setShow(false)}
			/>
			<Hidden lgUp>
				<Box className={classes.container}>
					<Container>
						<Grid container justify='center' alignItems='center'>
							<LogoWhite />
						</Grid>
					</Container>
				</Box>
			</Hidden>
			<Box className={classes.container}>
				<Container className={`${classes.headerItem}`}>
					<Hidden mdDown>
						<Box onClick={() => handleRedirect('/')} style={{ cursor: 'pointer' }}>
							<LogoWhite />
						</Box>
					</Hidden>
					{getSubdomainIcon()}
					{/* <Box position='relative' left='12px' display='none'>
						<Avatar src={logoApl} />
					</Box>
					<Box position='relative' left='12px' display='none'>
						<Avatar src={logoUnilever} />
					</Box>
					<Box position='relative' left='12px' display='none'>
						<Avatar src={logoCombiphar} />
					</Box>
					<Box position='relative' left='12px' display='none'>
						<Avatar src={logoSampoerna} />
					</Box> */}
					<Box display='flex' flex='0.2' justifyContent='center'>
						<CascaderCategory
							options={dataListFitlerCat}
							style={{ backgroundColor: '#fff' }}
						/>
					</Box>
					<Box className={classes.inputGroup}>
						<Autocomplete
							filterOptions={filterOptions}
							ref={inputRef}
							id='free-solo'
							freeSolo
							className={classes.textInputComplete}
							options={catalogSuggestion}
							getOptionLabel={(option) => optionLabel(option)}
							onInputChange={(_event, value, reason) => {
								if (reason == 'clear' || reason == 'input') handleChange(value, reason)
							}}
							onChange={(e, v, r) => {
								if (r == 'select-option') {
									if (v.typeCategory != 'all') {
										window.location.href = `/catalog/${v.slug}?q=${searchTerm}&category_name=${v.name}`
										// navigate(`/catalog/${v.slug}?q=${searchTerm}&category_name=${v.name}`)
									} else {
										window.location.href = `/catalog/search?q=${searchTerm}&category_name=${v.name}`
										// navigate(`/catalog/search?q=${searchTerm}&category_name=${v.name}`)
									}
								} else {
									handleChange(v)
								}
							}}
							openOnFocus={true}
							inputValue={searchTerm}
							renderOption={(option, { inputValue }) =>
								handleRenderOption(option, inputValue)
							}
							renderInput={(params) => (
								<TextField
									placeholder='Apa yang sedang anda butuhkan?'
									className={classes.textFieldInputComplete}
									{...params}
									onKeyDown={(e) => handleEnter(e)}
									// onFocus={() => handleChange(searchTerm)}
									InputProps={{
										...params.InputProps,
										disableUnderline: true,
									}}
								/>
							)}
						/>
						<IconButton
							className={classes.inputButton}
							variant='contained'
							onClick={submitSearch}
						>
							<SearchRounded fontSize='small' />
						</IconButton>
					</Box>
					<Box
						className={classes.headerMenu}
						justifyContent={isLoggedIn ? 'space-around' : 'space-between'}
					>
						<Hidden smDown>
							{renderPropfile()}
							<Box className={classes.headerMenu__item}>
								<Badge
									onClick={() => handleNavigate('/cart')}
									badgeContent={cartData.cartQty}
									color='error'
								>
									<ShoppingCart fontSize='large' />
								</Badge>
							</Box>
						</Hidden>
						<Hidden mdUp>
							<Box className={classes.headerMenu__item} position='relative'>
								{renderSmallMenu()}
							</Box>
						</Hidden>
					</Box>
				</Container>
			</Box>
		</>
	)
}

export default HeaderComponent
