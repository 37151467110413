import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <circle
        cx="20.5"
        cy="20.5"
        r="20.5"
        fill="#dc3545"
        data-name="Ellipse 101"
      ></circle>
      <g transform="translate(9 9)">
        <g data-name="Group 788">
          <path
            fill="#fff"
            d="M12 0a12 12 0 1012 12A12 12 0 0012 0zM5.335 4.155a10.283 10.283 0 0113.3 0L4.149 18.639A10.275 10.275 0 015.335 4.155zm13.33 15.689a10.283 10.283 0 01-13.3 0L19.851 5.361a10.276 10.276 0 01-1.186 14.484z"
            data-name="Path 375"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
