import React from 'react'
import {
	Cat1,
	Cat2,
	Cat5,
	Cat4,
	Cat3,
	Cat6,
	Cat7,
	Cat8,
	Cat9,
	Cat10,
	Cat11,
} from 'Components/svgs'

export const getMappingIcon = ({ color = '#081E3F', width, height }) => {
	let icons_map = []
	icons_map['agrikultur-dan-produk-segar'] = (
		<Cat1 stroke={color} width={width} height={height} />
	)
	icons_map['pakaian-tekstil-dan-aksesoris'] = (
		<Cat2 stroke={color} width={width} height={height} />
	)
	icons_map['elektronik-dan-telekomunikasi'] = (
		<Cat3 stroke={color} width={width} height={height} />
	)
	icons_map['kesehatan-dan-kecantikan'] = (
		<Cat4 stroke={color} width={width} height={height} />
	)
	icons_map['peralatan-rumah-penerangan-dan-konstruksi'] = (
		<Cat5 stroke={color} width={width} height={height} />
	)
	icons_map['peralatan-kantor-dan-penyimpanan'] = (
		<Cat6 stroke={color} width={width} height={height} />
	)
	icons_map['groceries'] = <Cat7 stroke={color} width={width} height={height} />
	icons_map['otomotif-dan-transportasi'] = (
		<Cat8 stroke={color} width={width} height={height} />
	)
	icons_map['olahraga-hobby-dan-hiburan'] = (
		<Cat9 stroke={color} width={width} height={height} />
	)
	icons_map['olahraga-hobi-dan-hiburan'] = (
		<Cat9 stroke={color} width={width} height={height} />
	)
	icons_map['peralatan-industri-mesin-dan-bahan-baku'] = (
		<Cat10 stroke={color} width={width} height={height} />
	)
	icons_map['jasa'] = <Cat11 stroke={color} width={width} height={height} />
	return icons_map
}

const getChildData = (data, id_p, slug, parent_type) => {
	let tmp_data1 = []
	data.forEach((val) => {
		if (
			parent_type == 'string' ||
			(parent_type == 'array' && id_p.includes(val.id))
		) {
			tmp_data1.push(val)
			let length = tmp_data1.length
			if (slug == null || (slug != null && slug != val.slug)) {
				if (val.child_category) {
					let child_category
					child_category = getChildData(val.child_category, id_p, slug, parent_type)
					if (child_category) {
						tmp_data1[length - 1].child_category = child_category
					}
				}
			} else if (slug != null && slug == val.slug) {
				tmp_data1[length - 1].child_category = []
			}
		}
	})
	return tmp_data1
}

export const getChildFilterData = (data, id_p, slug, parent_type) => {
	let tmp_data1 = []
	data.forEach((val) => {
		if (
			(parent_type == 'string' && val.id == id_p) ||
			(parent_type == 'array' && id_p.includes(val.id))
		) {
			tmp_data1.push(val)
			let length = tmp_data1.length
			if (slug == null || (slug != null && slug != val.slug)) {
				if (val.child_category) {
					let child_category
					child_category = getChildData(val.child_category, id_p, slug, parent_type)
					if (child_category) {
						tmp_data1[length - 1].child_category = child_category
					}
				}
			} else if (slug != null && slug == val.slug) {
				tmp_data1[length - 1].child_category = []
			}
		}
	})
	return tmp_data1
}

const getAggsData = (data, aggsId) => {
	const tmp_data = []
	if (aggsId) {
		data.forEach((val) => {
			if (aggsId.includes(val.id)) {
				tmp_data.push(val)
				if (val.child_category && val.child_category.length) {
					let length = tmp_data.length
					let child_category
					child_category = getAggsData(val.child_category, aggsId)
					if (child_category) {
						tmp_data[length - 1].child_category = child_category
					}
				}
			}
		})
	}
	return tmp_data
}

export const getAggsDataFilter = (data, aggsId) => {
	const tmp_data = []
	if (aggsId) {
		let filterData
		filterData = getAggsData(data, aggsId)
		if (filterData) {
			tmp_data.push(filterData)
		}
	}
	return tmp_data.length ? tmp_data[0] : tmp_data
}

export const getParentPath = (data, c_child, type, page = 'search') => {
	let tmp_data = []
	let getParent
	if (page == 'search') {
		data.forEach((val) => {
			if (type == 'id') {
				if (val.id == c_child) {
					tmp_data.push(val.parent_path)
				}
			} else if (type == 'slug') {
				if (val.slug == c_child) {
					tmp_data.push(val.parent_path)
				}
			}

			if (val.child_category && val.child_category.length) {
				getParent = getParentPath(val.child_category, c_child, type)
				if (getParent) {
					tmp_data.push(getParent)
				}
			}
		})
		if (tmp_data.length) {
			return tmp_data[0]
		}
	} else {
		tmp_data.push({ name: data.name, slug: data.slug })
		if (data.parent_id && data.parent_id.name) {
			getParent = getParentPath(data.parent_id, null, null, 'detail')
			if (getParent) {
				tmp_data.push(getParent)
			}
		}
		return tmp_data
	}
}

export const getAggsDataId = (aggsCatalog, allCategoryData, slug, type) => {
	if (aggsCatalog) {
		let tmp_data = []
		aggsCatalog[0].aggs_items.forEach((val) => {
			let parent
			if (type == 'id') {
				parent = getParentPath(allCategoryData, val.name, type)
			} else if (type == 'slug') {
				parent = getParentPath(allCategoryData.data.data, slug, type)
			}

			if (parent) {
				tmp_data.push(parent.split`/`.map((x) => +x))
			}
		})
		let aggDataId = [].concat(...tmp_data)

		return aggDataId
	}
}
