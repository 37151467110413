import React from 'react'

const SvgComponent = props => (
	<svg viewBox='0 0 92 92' {...props}>
		<path
			data-name='Path 185'
			d='M70 19.333L54.667 34.667h11.5a23.018 23.018 0 01-23 23 22.5 22.5 0 01-10.733-2.683l-5.6 5.6a30.613 30.613 0 0047-25.913h11.5zM20.167 34.667a23.018 23.018 0 0123-23A22.5 22.5 0 0153.9 14.35l5.6-5.6a30.613 30.613 0 00-47 25.913H1L16.333 50l15.334-15.333z'
			transform='translate(2.833 11.333)'
			fill='#081e3f'
		/>
		<path data-name='Path 186' d='M0 0h92v92H0z' fill='none' />
	</svg>
)

export default SvgComponent
