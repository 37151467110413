import React from "react"

const SvgComponent = props => (
    <svg width={20} height={20} viewBox="0 0 17.2 17.2" {...props}>
      <path
        d="M14.7 5.3h-.6V1c0-.6-.4-1-1-1H4c-.6 0-1 .4-1 1v4.3h-.5C1.1 5.3 0 6.4 0 7.8v3.8c0 1.4 1.1 2.6 2.5 2.5H3v2.1c0 .6.4 1 1 1h9.2c.5 0 1-.4.9-1v-2h.6c1.4 0 2.5-1.2 2.5-2.6V7.8c0-1.4-1.1-2.5-2.5-2.5zM5 2h7.2v3.3H5V2zm7.1 13.2H5v-4.1h7.1v4.1zm3.1-3.5c0 .3-.2.5-.5.5h-.6v-2.1c0-.6-.4-1-1-1H4c-.6 0-1 .4-1 1v2h-.5c-.3 0-.5-.2-.5-.5V7.8c0-.2.2-.5.5-.5h12.2c.3 0 .5.2.5.5v3.9z"
        fill="#656565"
      />
    </svg>
  )

export default SvgComponent
