import React from 'react'
import PropTypes from 'prop-types'

const SvgComponent = ({color, hoverColor, activeColor, isHover, isActive, ...props}) => {
	return(
	<svg width={16.736} height={19.127} viewBox='0 0 16.736 19.127' {...props}>
		<path
			data-name='Icon awesome-address-book'
			d='M16.288 5.977a.45.45 0 00.448-.448V4.035a.45.45 0 00-.448-.448h-.747V1.793A1.794 1.794 0 0013.748 0H1.793A1.794 1.794 0 000 1.793v15.541a1.794 1.794 0 001.793 1.793h11.955a1.794 1.794 0 001.793-1.793v-1.793h.747a.45.45 0 00.448-.448V13.6a.45.45 0 00-.448-.448h-.747v-2.393h.747a.45.45 0 00.448-.448V8.817a.45.45 0 00-.448-.448h-.747V5.977zM7.77 4.782a2.391 2.391 0 11-2.39 2.391 2.393 2.393 0 012.39-2.391zm4.184 8.846a.783.783 0 01-.837.717H4.423a.783.783 0 01-.837-.717v-.717A2.353 2.353 0 016.1 10.759h.187a3.848 3.848 0 002.974 0h.187a2.353 2.353 0 012.51 2.152z'
			fill={(isActive && activeColor) || (isHover && hoverColor) || color}
		/>
	</svg>
)}

SvgComponent.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  isHover: PropTypes.bool,
  isActive: PropTypes.bool,
}

SvgComponent.defaultProps = {
  color: '#6c757e',
  hoverColor: '#6c757e',
  activeColor: '#6c757e',
  isHover: false,
  isActive: false,
}

export default SvgComponent
