import { GLOBAL_LOADING, GLOBAL_ERROR } from '../types'

export const setGlobalLoading = (value) => {
	return {
		type: `SET_${GLOBAL_LOADING}`,
		payload: value,
	}
}

export const setGlobalError = (payload) => {
	return {
		type: `SET_${GLOBAL_ERROR}`,
		payload,
	}
}

export const ResetGlobalError = () => {
	return {
		type: `RESET_${GLOBAL_ERROR}`,
	}
}
