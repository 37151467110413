import React from 'react'

const SvgComponent = props => (
	<svg
		id='Layer_1'
		x='0px'
		y='0px'
		viewBox='0 0 71.5 74.1'
		xmlSpace='preserve'
		fill='none'
		strokeWidth='2'
		{...props}
	>
		<path d='M491.9 42.9c1.4.3 1.7 2.1.4 2.8L490 47c-.3.2-.8 1.7-.5 2.2l1.5 2.2c.8 1.2-.3 2.7-1.7 2.3l-2.6-.8c-.4-.1-1.8.7-1.9 1.2l-.5 2.7c-.3 1.4-2.1 1.7-2.8.4l-1.3-2.4c-.2-.4-1.8-.8-2.2-.5l-2.2 1.5c-1.2.8-2.7-.3-2.3-1.7l.8-2.6c.1-.5-.8-1.8-1.2-1.9l-2.7-.5c-1.4-.3-1.7-2.1-.4-2.8l2.4-1.3c.4-.2.8-1.8.5-2.2l-1.5-2.2c-.8-1.2.3-2.7 1.7-2.3l2.6.8c.4.1 1.8-.7 1.9-1.2l.5-2.7c.3-1.4 2.1-1.7 2.8-.4l1.3 2.4c.2.4 1.8.8 2.2.5l2.2-1.5c1.2-.8 2.7.3 2.3 1.7l-.8 2.6' />
		<circle cx={481.2} cy={46} r={5.1} />
		<path d='M491.2 32.9l-3.1-.6c-1.6-.3-2-2.5-.5-3.2l2.8-1.5c.4-.2.9-2.1.6-2.5l-1.8-2.6c-.9-1.4.4-3.1 1.9-2.7l3 .9c.5.1 2.1-.8 2.2-1.3l.6-3.1c.3-1.6 2.5-2 3.2-.5l1.5 2.8c.2.5 2.1.9 2.5.6l2.6-1.8c1.4-.9 3.1.4 2.7 1.9l-.9 3c-.1.5.8 2.1 1.3 2.2l3.1.6c1.6.3 2 2.5.5 3.2l-2.8 1.5c-.4.2-.9 2-.6 2.5l2 2.7c.9 1.4-.4 3.1-1.9 2.7l-3-.9c-.5-.1-2.1.8-2.2 1.3l-.6 3.1c-.3 1.6-2.5 2-3.2.5l-1.5-2.8c-.2-.4-2.1-.9-2.5-.6l-2.6 1.8c-1.4.9-3.1-.4-2.7-1.9' />
		<circle cx={500.6} cy={28.7} r={6} />
		<path d='M383.3 16.8c5.1-1.4 10.1-.4 13.3 3.1 5.1 5.5 3.5 15.2-3.5 21.6-7 6.4-16.7 7.2-21.8 1.7-3.7-4-3.9-10.3-1-15.9M360.6 56.6c-.6.5-1.5.5-2-.1l-1.6-1.7c-.5-.6-.5-1.5.1-2l7.4-6.8c.6-.5 1.5-.5 2 .1l1.6 1.7c.5.6.5 1.5-.1 2l-7.4 6.8z' />
		<path d='M394.1 22.3L374 40.8' />
		<path d='M396.3 27.2L378 44' />
		<path d='M377.8 30.4L371.2 36.5' />
		<path d='M389.4 19.7L384.1 24.6' />
		<path d='M386 22.8L395.1 32.6' />
		<path d='M382.9 26.8L393.2 37.9' />
		<path d='M380.3 31.1L389 40.6' />
		<path d='M373.2 30.7L384.8 43.3' />
		<path d='M367.9 47c1.9-1.8 4.6-2.2 6.9-1.3M369.2 39.5c.7 2.4.1 5.1-1.9 6.9' />
		<circle cx={376.2} cy={21.2} r={6} />
		<path d='M373.4 16.6c.6 1.4 1.7 2.5 3.3 3s3.2.2 4.4-.6M379 25.8c-.6-1.4-1.7-2.5-3.3-3-.8-.3-1.6-.3-2.4-.2' />
		<g>
			<path d='M253.6 34.9H282c2.6 0 4.8 2.1 4.8 4.8v5c0 2.6-2.1 4.8-4.8 4.8h-24.3' />
			<circle cx={277.5} cy={42.2} r={2.6} />
			<path d='M273.5 49.8v3.9c0 1.2 1 2.2 2.2 2.2h2.6c1.2 0 2.2-1 2.2-2.2v-3.9M256.4 19.9h18c3.4 0 6.1 2.8 6.1 6.2V31' />
			<path d='M255 40.2L269.2 40.2' />
			<path d='M257 44.1L267.2 44.1' />
			<path d='M250.2 28.1c2-1.1 3.4-3.1 3.4-5.5 0-2.7-1.7-4.9-4.1-5.8v6s-1 .5-2.3.5c-1.3 0-2.3-.5-2.3-.5v-6c-2.4.9-4.1 3.2-4.1 5.8 0 2.4 1.4 4.5 3.5 5.6v16.4c-2.1 1-3.5 3.1-3.5 5.6 0 2.7 1.7 4.9 4.1 5.8v-6s1-.5 2.3-.5 2.3.5 2.3.5v6c2.4-.9 4.1-3.2 4.1-5.8 0-2.4-1.4-4.5-3.4-5.5V28.1z' />
		</g>
		<g>
			<path d='M133.8 32L133.8 29.2 144 29.2 144 32' />
			<path d='M138.5 37.4L131.1 37.4 133.8 32 140.7 32' />
			<path d='M144 32L141.2 37.4' />
			<path d='M141.2 37.4L144 32 146.7 37.4z' />
			<path d='M141.2 37.4L141.2 56.7 131.1 56.7 131.1 37.4' />
			<path d='M141.2 37.4H146.7V56.7H141.2z' />
			<path d='M133.4 40.1H138.5V42.6H133.4z' />
			<g>
				<path d='M133.8 26.3L133.8 19.4 162.9 19.4' />
				<path d='M162.9 19.4L133.8 19.4 139.4 16 168.4 16z' />
				<path d='M162.9 56.7L162.9 19.4 168.4 16 168.4 53.3 162.9 56.7' />
				<path d='M133.8 23.8L160.1 23.8' />
			</g>
			<g>
				<path d='M160.2 49.5v-6.7c0-2.8-3.3-8.3-3.3-8.3-.2-.4-.1-.9.3-1.2l.4-.3c.4-.3.7-.8.7-1.3v-.1c0-.5-.4-.9-.9-.9h-5.3c-.5 0-.9.4-.9.9v.3c0 .5.3 1 .8 1.2l.3.2c.4.2.6.7.3 1.1 0 0-3.3 5.4-3.3 8.3v6.7' />
				<path d='M149.3 42.8L157.4 42.8' />
				<path d='M150.1 49.5L157.4 49.5' />
			</g>
			<path d='M160.2 49.5L160.2 56.7 149.3 56.7 149.3 49.5' />
		</g>
		<g>
			<path d='M17.6 38.9h-4.9c-2.7 0-4.6-2.5-4.2-5.6l1.9-15.2c.4-3.1 3.3-5.6 6.4-5.6h8.8c3.1 0 6 2.5 6.4 5.6l1.9 15.2c.4 3.1-1.9 5.6-5 5.6h-7.7M32.9 44.7H9.5c-1.8 0-3.2 1.4-3.2 3.2 0 1.8 1.4 3.2 3.2 3.2h23.4c1.8 0 3.2-1.4 3.2-3.2 0-1.8-1.4-3.2-3.2-3.2z' />
			<path d='M21.2 39L21.2 44.7' />
			<path d='M39.7 38.2c-2 0-3.5 1.6-3.5 3.5v6.2M2.8 38.2c2 0 3.5 1.6 3.5 3.5v6.2' />
			<path d='M21.2 53.6L21.2 60.2' />
			<path d='M27.4 60.2c0-1.1-.9-2.1-2.1-2.1h-8.2c-1.1 0-2.1.9-2.1 2.1' />
			<g>
				<path d='M44.5 30H66.7V57.7H44.5z' />
				<path d='M47.5 38.4L47.5 32.9 63.6 32.9 63.6 38.4 50.5 38.4' />
				<path d='M45.8 60.1L48.2 60.1' />
				<path d='M62.9 60.1L65.3 60.1' />
				<path d='M47.5 46.7L47.5 41.2 63.6 41.2 63.6 46.7 50.5 46.7' />
				<path d='M47.5 54.9L47.5 49.4 63.6 49.4 63.6 54.9 50.5 54.9' />
			</g>
		</g>
		<g>
			<path d='M-77 44.4c-1.3.9-2.1 2.4-2.1 4v4.9h-19.2v-4.9c0-1.7-.9-3.2-2.3-4.1' />
			<path d='M-100.7 62h4.4v-1.9h15V62h7v-8.7c2.7 0 4.9-2.2 4.9-4.9s-2.2-4.9-4.9-4.9v-.3c0-8-6.5-14.5-14.5-14.5s-14.5 6.5-14.5 14.5v.3c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9v8.6' />
			<g>
				<ellipse cx={-64.8} cy={12.2} rx={5.9} ry={1.5} />
				<path d='M-70.6 12L-75 22.6V22.9c0 1.2 3.5 2.3 8.1 2.5M-64 61.6V25.5c5.3-.1 9.5-1.2 9.5-2.6v-.2-.1L-59 12' />
				<path d='M-69.9 62L-58 62' />
			</g>
		</g>
		<g>
			<path d='M-216.9 15.7h-4.2v9.8c0 5.4 4.4 9.8 9.8 9.8s9.8-4.4 9.8-9.8v-9.8h-3.9M-211.1 35.3v14.3c0 3.6 3 6.6 6.6 6.6 3.6 0 6.6-3 6.6-6.6v-6.4c0-4.1 3.3-7.4 7.4-7.4s7.4 3.3 7.4 7.4v6.4c2 0 3.7 1.7 3.7 3.7S-181 57-183 57s-3.7-1.7-3.7-3.7c0-.7.2-1.4.6-2' />
		</g>
		<g>
			<path d='M-293.8 47.4v5.2c0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6v-4.9c0-1.4-1.2-2.6-2.6-2.6-1.4 0-2.6 1.2-2.6 2.6v5.8c0 3.2-2.6 5.8-5.8 5.8-3.2 0-5.8-2.6-5.8-5.8V39.9h6.1c1.6 0 3-1.3 3-3v-9.3h1.6c.9 0 1.6-.7 1.6-1.6v-1.3c0-.9-.7-1.6-1.6-1.6h-5.6v-8.4c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v8.4h-8.6v-8.4c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v8.4h-5.6c-.9 0-1.6.7-1.6 1.6V26c0 .9.7 1.6 1.6 1.6h1.6v9.3c0 1.6 1.3 3 3 3h5.6' />
			<path d='M-289.9 27.9h-3l3.2-5.3c.4-.6.1-1.2-.5-1.2h-4c-.6 0-1 0-1.3.7l-3.3 8.5c-.2.5 0 1 .5 1.2h3.8l-2.8 7.1c-.2.5 7.7-9.4 7.9-9.8.2-.4-.1-1-.5-1.2z' />
		</g>
		<path d='M-433.5 17.6l-15.9 3.1c-.6.1-.9.7-.8 1.2l2 8.1c.1.4.5.7 1 .7h6.8c.5 0 1 .4 1 1v24.4c0 .5.4 1 1 1h29.7c.5 0 1-.4 1-1V31.7c0-.5.4-1 1-1h6.9c.4 0 .8-.3.9-.7l2.3-8.1c.2-.6-.2-1.1-.8-1.2l-16.2-3.1c-.6-.1-1.1.3-1.2.8-.5 4.4-4.3 7.8-8.8 7.8-3.6 0-6.6-2.1-8-5.1' />
		<g>
			<path d='M-537.4 23.7c1.2 3.1 2.9 4.4 2.9 4.4s1.6-1.3 2.9-4.4c1.6-3.9-2.9-10-2.9-10s-4.4 6.1-2.9 10zM-532.9 59s2.8-.3 6.4-2.8c3.2-2.2 3.8-4.4 4.1-8.8 0 0-3.5 1.2-7 3.7-1.9 1.4-2.7 2.7-3.1 4.9M-535.9 59s-2.8-.3-6.4-2.8c-3.2-2.2-3.8-4.4-4.1-8.8 0 0 3.5 1.2 7 3.7 1.9 1.4 2.7 2.7 3.1 4.9M-532.9 47.5s2.8-.3 6.4-2.8c3.2-2.2 3.8-4.4 4.1-8.8 0 0-3.5 1.2-7 3.7-1.9 1.4-2.7 2.7-3.1 4.9M-535.9 47.5s-2.8-.3-6.4-2.8c-3.2-2.2-3.8-4.4-4.1-8.8 0 0 3.5 1.2 7 3.7 1.9 1.4 2.7 2.7 3.1 4.9M-532.9 35.9s2.8-.3 6.4-2.8c3.2-2.2 3.8-4.4 4.1-8.8 0 0-3.5 1.2-7 3.7-1.9 1.4-2.7 2.7-3.1 4.9M-535.9 35.9s-2.8-.3-6.4-2.8c-3.2-2.2-3.8-4.4-4.1-8.8 0 0 3.5 1.2 7 3.7 1.9 1.4 2.7 2.7 3.1 4.9' />
		</g>
		<g>
			<path d='M601.5 21.2c-4.1-.7-7 3.7-8.9 3.7-2.4 0-8.1-3.1-10.9-2.4-2.8.6-4.1 15.5-3.1 17.9s11.2 1.8 10.6 4.7c-.6 2.7 1.2 4.4 4.6 4 .8 1.8 2.3 2.7 4.8 2.7.2 1.8 3.7 3.3 5 2.8-.2 2.3 3.6 3.9 5.2 2.7.7-.5 1-1 1.4-1.7' />
			<path d='M630.4 42.8c1.6-.2 5.3-1 5.9-2.5 2.4-6.4-1.4-16.9-3.2-17.8-1.8-.8-6.7 1.9-10.1 2.1-3.8.3-8.8-6-12.1-6-2.5 0-7.1 3.6-8 5.4-.9 1.9-1.9 6.2-1.9 8.9 0 2.8 6.1 3.5 6.7 1.5.9-2.9 1.1-5.5 1.7-6.3s2-2.1 3.7-1c7.5 4.8 15.1 8.3 15.7 9.5.8 1.8-1.1 4.7-3 4.3 1.5 2-.8 6.2-3.6 4.9-.1 2.2-1.3 4.6-5.2 3.7.2 2.1-1.7 3.9-2.7 3.8-.5 0-1.2-.2-2.1-.4-.8-.2 0-1.8.1-2.3.5-2.7-1.5-3.8-3.8-3.8-1.2-2.7-2.8-3.6-5.9-2.6-1.9-2.2-4.8-2.5-6.4.6.2-2.2-2.7-3.4-4.2-2.5' />
			<path d='M623.1 39.4L618.9 37.2' />
			<path d='M619.4 44.2L616.5 42.5' />
			<path d='M614.8 47.7L614.5 47.6' />
			<path d='M595.2 46.9L595 47.3' />
			<path d='M601.5 46.6L599.9 49.5' />
			<path d='M607.1 49.1L605.1 52.4' />
		</g>
	</svg>
)

export default React.memo(SvgComponent)
