import React from 'react'

const SvgComponent = props => (
	<svg viewBox='0 0 87.624 61.338' {...props}>
		<path
			data-name='Icon material-supervisor-account'
			d='M66.527 38.168a10.92 10.92 0 10-7.755-3.2 10.953 10.953 0 007.755 3.2zm-32.859-4.381a13.089 13.089 0 10-9.31-3.834 13.144 13.144 0 009.31 3.834zM66.527 46.93c-8.017 0-24.1 4.031-24.1 12.05v9.858h48.197V58.98c0-8.018-16.079-12.05-24.097-12.05zm-32.859-4.38C23.461 42.55 3 47.676 3 57.884v10.953h30.668V58.98c0-3.725 1.446-10.252 10.384-15.2a53.817 53.817 0 00-10.384-1.23z'
			transform='translate(-3 -7.499)'
			fill='#081e3f'
		/>
	</svg>
)

export default SvgComponent
