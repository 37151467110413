import React from 'react'

const SvgComponent = props => (
	<svg viewBox='0 0 72.988 72.929' {...props}>
		<path
			data-name='Icon ionic-ios-search'
			d='M76.842 72.4L56.474 51.841a29.026 29.026 0 10-4.406 4.459L72.3 76.728a3.137 3.137 0 004.542-4.328zM33.665 56.552a22.92 22.92 0 1116.21-6.713 22.919 22.919 0 01-16.21 6.713z'
			transform='translate(-4.711 -4.773)'
			fill='#081e3f'
		/>
	</svg>
)

export default SvgComponent
