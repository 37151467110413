import React from "react"

const SvgComponent = props => (
	<svg x="0px" y="0px" viewBox="0 0 32.913 4.821" xmlSpace="preserve" {...props}>
		<g  id="Component_70_1" data-name="Component 70 – 1" transform="translate(0 0.411)">
    	<path id="Path_448" data-name="Path 448" d="M579.016,229.251H610.33l-5.644-3.91" transform="translate(-579.016 -225.341)" fill="none" stroke="#a9acaf" stroke-width="1"/>
  	</g>
	</svg>
)


export default SvgComponent
