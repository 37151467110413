import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.primary.main,
		zIndex: 3,
		position: 'relative',
	},
	image: {
		width: 110,
		transform: 'translateX(-15px)',
	},
	headerItem: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(1),
		color: theme.palette.common.white,
		[theme.breakpoints.up('lg')]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	inputGroup: {
		display: 'flex',
		flex: 1,
		marginRight: theme.spacing(2),
		overflow: 'hidden',
		borderRadius: 5,
		height: 36,
		[theme.breakpoints.down('md')]: {
			marginLeft: 0,
		},
	},
	inputButton: {
		// marginTop:'-.6%',
		padding: 0,
		width: 45,
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: 0,
		flexWrap: 'no-wrap',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	textInput: {
		flex: 1,
		padding: '0 10px',
		border: 0,
		backgroundColor: '#fff',
	},

	textInputComplete: {
		flex: 1,
		padding: '2px 1px 1px 10px',
		border: 0,
		backgroundColor: '#fff',
		'& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child':
			{
				padding: '7px 6px 6px 15px',
			},
		'& .MuiAutocomplete-endAdornment': {
			top: 'calc(50% - 16px)',
			right: 0,
			position: 'absolute',
		},
	},
	textFieldInputComplete: {
		marginLeft: '-10px',
		width: '101.4%',
	},
	headerMenu: {
		flex: 0.25,
		display: 'flex',
		// justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			flex: 0,
			gridTemplateColumns: '',
		},
	},
	headerMenu__item: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',
		color: theme.palette.common.white,
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			'& .MuiTypography-body2': {
				fontSize: '0.7rem',
			},
		},
	},
	customMenu: {
		paddingTop: 0,
		paddingBottom: 0,
		'&:before': {
			top: -9,
			left: '68%',
			width: 20,
			height: 10,
			content: '""',
			display: 'inline-block',
			position: 'absolute',
			clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
			background: theme.palette.common.white,
		},

		[theme.breakpoints.up('xl')]: {
			'&::before': {
				top: -9,
				left: '43%',
				width: 20,
				height: 10,
				content: '""',
				display: 'inline-block',
				position: 'absolute',
				clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
				background: theme.palette.common.white,
			},
		},
	},
	iconButton: {
		padding: 0,
	},
	borderMenu: {
		borderBottom: '1px solid',
		borderBottomColor: theme.palette.grey.A100,
		fontWeight: 'bold',
	},
	btnLogout: {
		color: theme.palette.error.main,
	},
	backdrop: {
		zIndex: 1,
	},
	popper: {
		top: '37px!important',
		left: '67.7%!important',
		position: 'absolute!important',
		transform: 'translate3d(0px, 39px, 0px)!important',
	},
	containerPadder: {
		width: 'calc( 100% - 250px )',
	},
}))

export default useStyles
